import LabelWithBorder from "components/common/LabelWithBorder";
import LabelWithContent from "components/common/LabelWithContent";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import FrequencyEditor from "./FrequencyEditor";

const labelClassName =
  "ml-2 w-full pr-6 font-inter font-medium text-[12px] leading-[18px]";

const RenderFrequencyComponent = (props: {
  isPaused: boolean;
  predecessorAgreementId?: number;
  lagInMinutes?: number;
  executionCronExpressionText: string;
  frequency: FrequencyType;
}) => {
  const frequencyText = props.executionCronExpressionText;

  return (
    <div className={"truncate max-w-300px w-auto flex gap-2"}>
      {props.isPaused && (
        <LabelWithBorder
          text="Paused"
          color="ilapBlue"
          showBorder={true}
          isBold={true}
        />
      )}
      {props.frequency === FrequencyType.Predecessor ? (
        <>{`Predecessing ID${props.predecessorAgreementId} Daily at ${frequencyText} +${props.lagInMinutes} minutes`}</>
      ) : (
        <>{frequencyText}</>
      )}
    </div>
  );
};

const FrequencyViewer = (props: FrequencyFieldOptions) => {
  return (
    <LabelWithContent
      label="Frequency"
      labelIcon={
        props.isExchangeAgreementActive &&
        props.isCurrentUserSender &&
        (props.frequency === FrequencyType.Predecessor ? (
          <span className="font-inter font-medium text-xs leading-[18px]">
            Predecessor IEA
          </span>
        ) : null)
      }
      content={
        <div className={labelClassName}>
          <RenderFrequencyComponent
            executionCronExpressionText={props.executionCronExpressionText}
            frequency={props.frequency}
            isPaused={props.isPaused}
            lagInMinutes={props.lagInMinutes}
            predecessorAgreementId={props.predecessorAgreementId}
          />
        </div>
      }
    />
  );
};

interface FrequencyFieldOptions {
  isPaused: boolean;
  isExchangeAgreementActive: boolean;
  isCurrentUserSender?: boolean;
  frequency: FrequencyType;
  executionCronExpressionText: string;
  executionCronExpression: string;
  lagInMinutes?: number;
  predecessorAgreementId?: number;
}

interface Props {
  isEdit?: boolean;
  ownerExchangeRole?: ExchangeRoleEnum;
  onValueChange?: (fieldName: string, value: any) => void;
  onPredecessorAgreementIdChange?: (value: any) => void;
  onLagMinutesChange?: (value: any) => void;
  viewerConfig?: FrequencyFieldOptions;
  onFrequencyTypeChange?: (value: FrequencyType) => void;
  onCronExpressionChange: (value: string) => void;
}

export default function FrequencyField(props: Props) {
  return props.isEdit ? (
    <FrequencyEditor
      ownerExchangeRole={props.ownerExchangeRole!}
      onValueChange={props.onValueChange!}
      defaultValue={props.viewerConfig?.executionCronExpression}
      lagInMinutes={props.viewerConfig?.lagInMinutes}
      onPredecessorAgreementIdChange={props.onPredecessorAgreementIdChange}
      onLagMinutesChange={props.onLagMinutesChange}
      onFrequencyTypeChange={props.onFrequencyTypeChange}
      frequencyType={props.viewerConfig?.frequency!}
      onCronExpressionChange={props.onCronExpressionChange}
    />
  ) : (
    <FrequencyViewer {...props.viewerConfig!} />
  );
}
