import { getDataAsync } from "./API";
import MappingFormulaResponse from "interfaces/response/MappingFormulaResponse";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";

export const getMappingFormulasAsync = async () => {
  // For now, we are only getting the MappingFormulas for
  // Activity, Schedule and ResourceAssignments.
  const apiUrl = `MappingFormulas?PlanningObjectTypes=${PlanningObjectTypes.Activity},${PlanningObjectTypes.Schedule},${PlanningObjectTypes.ResourceUsage}`;

  return await getDataAsync<MappingFormulaResponse[]>(apiUrl);
};
