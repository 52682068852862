import TenantResponse from "interfaces/response/TenantResponse";
import TenantStatusLabel from "./TenantStatusLabel";

interface Props {
  data: { data: TenantResponse };
}

export default function StatusDisplayComponent(props: Props) {
  const tenant = props.data.data;
  return <TenantStatusLabel tenantStatus={"N/A"} />;
}
