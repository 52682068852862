import PromineoViewGrid, {
  RowOperationConfig
} from "components/common/grid/PromineoViewGrid";
import "components/common/grid/styles/PromineoUIGrid.css";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import { ReactElement, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ACTIVE_IEA } from "shared/constants/RoutePathConstants";
import { booleanSortComparator } from "shared/utilities/CommonUtility";
import DownloadStatusCellComponent from "./DownloadStatusCellComponent";
import DownloadWarningCountComponent from "./DownloadWarningCountComponent";
import LastSucessfulTransferCellComponent from "./LastSucessfulTransferCellComponent";
import UploadActivityCountComponent from "./UploadActivityCountComponent";
import UploadStatusCellComponent from "./UploadStatusCellComponent";
import UploadWarningCountComponent from "./UploadWarningCountComponent";
import TitleCellComponent from "./active-iea-grid-cell-component/TitleCellComponent";

interface Props {
  activeIEAs: ActiveExchangeAgreementListingResponse[];
  height: number;
  onCreateNewClick: () => void;
  gridWidget?: ReactElement;
}

const ieaInfoTabIndex = 0;
const eventLogTabIndex = 1;

export default function ActiveIEAsGrid(props: Props) {
  const { activeIEAs, height } = props;
  let navigate = useNavigate();

  const handleNewIEAClick = useCallback(() => {
    props.onCreateNewClick();
  }, []);

  const handleViewIEAClick = useCallback(
    (activeIEA: ActiveExchangeAgreementListingResponse) => {
      navigate(`${ACTIVE_IEA}/${activeIEA.id}`, {
        state: { selectedTabIndex: ieaInfoTabIndex },
      });
    },
    []
  );

  const handleEventLogClick = useCallback(
    (activeIEA: ActiveExchangeAgreementListingResponse) => {
      navigate(`${ACTIVE_IEA}/${activeIEA.id}`, {
        state: { selectedTabIndex: eventLogTabIndex },
      });
    },
    []
  );

  const handleRowClick = useCallback((data: any) => {
    handleViewIEAClick(data.data);
  }, []);

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
    displayAddNewButton: true,
    addNewButtonText: "New IEA",
    onClickAddNew: handleNewIEAClick,
  });

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    items: [
      {
        onClick: (data) => handleViewIEAClick(data.data),
        text: "View",
        visible: true,
      },
      {
        onClick: (data) => handleEventLogClick(data.data),
        text: "Event log",
        visible: true,
      },
    ],
  });

  return (
    <>
      <PromineoViewGrid
        dataSource={activeIEAs}
        height={height}
        className="active-ieas-grid promineo-ui-grid"
        onRowClick={handleRowClick}
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig.current}
        additionalWidget={props.gridWidget}
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"Upload status"}
          cellComponent={UploadStatusCellComponent}
        />
        <Column
          cssClass={"custom-control-padding ignore-row-click"}
          caption={"Download status"}
          cellComponent={DownloadStatusCellComponent}
          width={"auto"}
        />
        <Column caption={"IEA Id"} dataField="id" alignment="left" width={70} />
        <Column
          caption={"IEA Title"}
          dataField="title"
          alignment="left"
          cellComponent={TitleCellComponent}
          calculateSortValue={"updateRequired"}
          sortingMethod={booleanSortComparator}
          sortOrder={"desc"}
          sortIndex={1}
        />
        <Column
          caption={"Template"}
          dataField="templateName"
          alignment="left"
        />
        <Column
          caption={"Sending connector (host system)"}
          dataField="sendingConnectorText"
          alignment="left"
        />
        <Column
          caption={"Receiving connector (host system)"}
          dataField="receivingConnectorText"
          alignment="left"
        />
        <Column caption={"Sender"} dataField="sender" alignment="left" />
        <Column caption={"Receiver"} dataField="receiver" alignment="left" />
        <Column
          caption={"#Of Acts"}
          dataField="uploadActivityCount"
          alignment="left"
          cellComponent={UploadActivityCountComponent}
        />
        <Column
          caption={"Last successful transfer"}
          dataField="lastSuccessfulTransfer"
          alignment="left"
          cellComponent={LastSucessfulTransferCellComponent}
          sortIndex={0}
          sortOrder={"asc"}
        />
        <Column
          caption={"#Upload warnings"}
          dataField="uploadWarningCount"
          alignment="left"
          cellComponent={UploadWarningCountComponent}
        />
        <Column
          caption={"#Download warnings"}
          dataField="downloadWarningCount"
          alignment="left"
          cellComponent={DownloadWarningCountComponent}
        />
        <Column caption={"Role"} dataField="roleText" alignment="left" />
      </PromineoViewGrid>
    </>
  );
}
