import ExchangeAgreementSimplifiedResponse from "interfaces/response/ExchangeAgreementSimplifiedResponse";
import { useDispatch } from "react-redux";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import { AppDispatch } from "store/store";
import LabelWithContent from "../../components/common/LabelWithContent";
import CommentField from "./CommentField";
import IEAContentBodyWithTitle from "./IEAContentBodyWithTitle";
import ExpirationDateField from "./active-ieas/ExpirationDateField";
import FrequencyField from "./active-ieas/FrequencyField";
import StartDateField from "./active-ieas/StartDateField";

interface Props {
  selectedExchangeAgreementId: number;
  isExchangeAgreementActive: boolean;
  startDate: string;
  expirationDate: string;
  executionCronExpressionText: string;
  executionCronExpression: string;
  isPaused: boolean;
  comments: string;
  successorAgreements?: ExchangeAgreementSimplifiedResponse[];
  isCurrentUserSender?: boolean;
  frequency: FrequencyType;
  lagInMinutes?: number;
  predecessorAgreementId?: number;
  allowEdit?: boolean;
  ownerRole?: ExchangeRoleEnum;
  onStartDateChange?: (value: string) => void;
  onExpirationDateChange?: (value: string) => void;
  onCommentChange?: (value: string) => void;
  onCronExpressionChange?: (value: string) => void;
  onPredecessorAgreementIdChange?: (value: any) => void;
  onLagMinutesChange?: (value: any) => void;
  onFrequencyTypeChange?: (value: FrequencyType) => void;
}

export default function GeneralIEAInfo(props: Props) {
  const {
    isExchangeAgreementActive,
    startDate,
    expirationDate,
    executionCronExpressionText,
    executionCronExpression,
    isPaused,
    comments,
    successorAgreements,
    isCurrentUserSender,
    frequency,
    lagInMinutes,
    predecessorAgreementId,
    allowEdit,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const labelClassName =
    "ml-2 w-full pr-6 font-inter font-medium text-[12px] leading-[18px]";

  return (
    <IEAContentBodyWithTitle
      title="General"
      bodyHeight="h-auto"
      content={
        <div className="grid grid-cols-4 gap-2">
          <StartDateField
            startDate={startDate}
            isEdit={allowEdit && new Date(startDate) > new Date()}
            onValueChange={props.onStartDateChange}
          />
          <div className="col-start-2 row-span-2">
            <FrequencyField
              viewerConfig={{
                executionCronExpression: executionCronExpression,
                executionCronExpressionText: executionCronExpressionText,
                frequency: frequency,
                isCurrentUserSender: isCurrentUserSender,
                isExchangeAgreementActive: isExchangeAgreementActive,
                isPaused: isPaused,
                lagInMinutes: lagInMinutes,
                predecessorAgreementId: predecessorAgreementId,
              }}
              isEdit={props.allowEdit}
              onCronExpressionChange={props.onCronExpressionChange!}
              onPredecessorAgreementIdChange={
                props.onPredecessorAgreementIdChange
              }
              onLagMinutesChange={props.onLagMinutesChange}
              ownerExchangeRole={props.ownerRole}
              onFrequencyTypeChange={props.onFrequencyTypeChange}
            />
          </div>
          <div className={`col-start-3 col-span-2 ${allowEdit ? 'row-span-3' : 'row-span-2'}`}>
            <CommentField
              comments={comments}
              onChange={(fieldName: string, value: string) =>
                props.onCommentChange?.(value)
              }
              showEditor={allowEdit}
            />
          </div>
          <ExpirationDateField
            expirationDate={expirationDate}
            isEdit={allowEdit}
            onValueChange={(fieldName: string, value: string) =>
              props.onExpirationDateChange?.(value)
            }
          />
          {isExchangeAgreementActive && successorAgreements && (
            <LabelWithContent
              label="Successor IEA(s)"
              content={
                <div className={`${labelClassName} truncate`}>
                  {successorAgreements.toString()}
                </div>
              }
            />
          )}
        </div>
      }
    />
  );
}
