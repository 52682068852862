import { Column, Scrolling } from "devextreme-react/data-grid";
import { useCallback, useState } from "react";
import PromineoDropdownBox from "components/common/dropdown-box/PromineoDropdownBox";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import MappingFormulaResponse from "interfaces/response/MappingFormulaResponse";

interface Props {
  dataSource: MappingFormulaResponse[];
  onFormulaSelectionChange: (newFormula: string) => void;
}

export default function ExampleFormulaPickerDropdown(props: Props) {
  const { dataSource, onFormulaSelectionChange } = props;
  const dropdownOptions = { valueExpr: "id" };
  const [isOpened, setIsOpened] = useState(false);

  const handleRowClick = (evt: any) => {
    onFormulaSelectionChange(evt.data.formula);
    setIsOpened(false);
  };

  const handleOpenedChanged = (value: boolean) => {
    setIsOpened(value);
  };

  const getGridView = useCallback(() => {
    return (
      <PromineoViewGrid
        dataSource={dataSource}
        height={288}
        width={700}
        showColumnHeaders={false}
        onRowClick={handleRowClick}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column dataField={"formula"} caption="Example formula" />
        <Column dataField={"description"} caption="Description" />
      </PromineoViewGrid>
    );
  }, [dataSource]);

  return (
    <>
      <PromineoDropdownBox
        placeholder="Select"
        {...dropdownOptions}
        width={240}
        dropDownOptions={{
          width: 700,
          maxHeight: 380,
          height: "auto",
        }}
        dataSource={dataSource}
        className={`promineo-tree-view-dropdown w-full`}
        contentComponent={getGridView}
        onOpenedChange={handleOpenedChanged}
        opened={isOpened}
      />
    </>
  );
}
