import PromineoButtonWithIcon from "components/common/controls/buttons/PromineoButtonWithIcon";
import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";
import AddIcon from "components/icons/AddIcon";

interface Props {
  onClick: (data: any) => void;
}

export default function AddButtonCellTemplate(props: Props) {
  const { onClick } = props;
  return (
    <PromineoButtonWithIcon
      Icon={<AddIcon />}
      text="Add"
      onClick={onClick}
      variant={PromineoButtonType.Primary}
    />
  );
}
