import { IButtonOptions } from "devextreme-react/button";
import AddIcon from "components/icons/AddIcon";
import PromineoButtonWithIcon from "./PromineoButtonWithIcon";
import { PromineoButtonType } from "./PromineoButton";
import { ReactNode } from "react";

interface Props {
  text?: string;
  Icon?: ReactNode;
}

export default function AddNewButton(props: Props & IButtonOptions) {
  return (
    <PromineoButtonWithIcon
      {...props}
      variant={PromineoButtonType.Primary}
      text={props.text || "Add new"}
      Icon={props.Icon ? props.Icon : <AddIcon />}
    />
  );
}
