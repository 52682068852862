import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import ClientResponse from "interfaces/response/autonomous-components/ClientResponse";
import { ClientTypeEnum } from "shared/enums/ClientTypeEnum";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { removeAutonomousComponentAsync } from "store/actions/AutonomousComponentActions";
import { removeIlapAnalyticsClientAsync } from "store/actions/IlapAnalyticsActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  component?: ClientResponse;
  onClose: () => void;
  clientType: ClientTypeEnum;
}

export default function AutonomousComponentDeleteConfirmationDialog(
  props: Props
) {
  const dispatch = useAppDispatch();

  const handleDeleteConfirm = () => {
    if (props.component) {
      displayLoadingPanel();

      const promise = props.clientType === ClientTypeEnum.AutonomousComponent
        ? dispatch(removeAutonomousComponentAsync(props.component.id))
        : dispatch(removeIlapAnalyticsClientAsync(props.component.id))

      promise
        .unwrap()
        .then(() => {
          toastSuccess("Component deleted successfully.");
          props.onClose();
        })
        .finally(hideLoadingPanel);
    }
  };

  const handleCancel = () => {
    props.onClose();
  };

  return props.component ? (
    <PromineoConfirmationDialog
      onConfirm={handleDeleteConfirm}
      onCancel={handleCancel}
      content={`Are you sure, you want to delete this autonomous component?`}
      cancelButtonText="No"
      confirmButtonText="Yes"
    />
  ) : <></>;
}
