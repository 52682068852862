import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";
import LabelWithContent from "components/common/LabelWithContent";
import WbsComponent from "features/setup/configs/host-system/safran/WbsComponent";

interface Props extends BaseHostSystemComponentProps {
  wbsCandidates: HostFieldDropdownValueResponse[];
  wbsValue: string;
}

export default function WbsField(props: Props) {
  const {
    mode,
    fieldName,
    wbsCandidates,
    wbsValue,
    handleConfigurationValueChanged,
  } = props;

  return (
    <LabelWithContent
      label="WBS"
      content={
        <WbsComponent
          mode={mode}
          fieldName={fieldName}
          wbsCandidates={wbsCandidates}
          wbsValue={wbsValue}
          handleConfigurationValueChanged={handleConfigurationValueChanged}
        />
      }
    />
  );
}
