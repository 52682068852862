import EditIcon from "components/icons/EditIcon";
import TenantStatusLabel from "../TenantStatusLabel";
import PromineoModalHeader from "components/modal/PromineoModalHeader";

interface Props {
  onEditIconClick: () => void;
  tenantName: string;
  status: string;
  isEdit: boolean;
  onClose: () => void;
}

export default function TenantDetailHeader(props: Props) {
  const { onEditIconClick, tenantName, status, isEdit } = props;

  return (
    <PromineoModalHeader
      onCancel={props.onClose}
      text={isEdit ? "Edit tenant" : "Tenant information"}
      showCloseIcon={!isEdit}
    >
      <div className="mt-2">
        <div className="flex justify-between">
          <div className="flex  items-center gap-4">
            <div className="text-15px leading-22px">{tenantName}</div>
            {!isEdit && (
              <div className="cursor-pointer" onClick={onEditIconClick}>
                <EditIcon />
              </div>
            )}
          </div>
          <TenantStatusLabel tenantStatus={status} />
        </div>
      </div>
    </PromineoModalHeader>
  );
}
