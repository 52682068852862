import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { useCallback } from "react";
import SystemAdminControlControlLabelComponent from "./SystemAdminControlControlLabelComponent";
import { useNavigate } from "react-router-dom";
import {
  SYS_ADMIN_ILAP_TERMS,
  SYS_ADMIN_ILAP_TERM_NEW,
} from "shared/constants/RoutePathConstants";
import SystemAdminFieldResponse from "interfaces/response/SystemAdminFieldResponse";
import { FieldContentControlLevel } from "shared/enums/feature/FieldContentControlLevel";

interface Props {
  fields: SystemAdminFieldResponse[];
  height: number;
}

const disableText =
  "Content control level can't be changed as tenant level content control exists";

export default function IlapTermsGrid(props: Props) {
  let navigate = useNavigate();

  const ContentControlComponent = useCallback(
    (props: { data: SystemAdminFieldResponse }) => {
      var field = props.data;

      var isDisabled =
        field.controlledOn === FieldContentControlLevel.System
          ? field.hasSystemFieldValues
          : field.hasTenantOrTemplateFieldValues;
      return (
        <SystemAdminControlControlLabelComponent
          field={field}
          isDisabled={isDisabled}
          disableText={disableText}
        />
      );
    },
    []
  );

  const handleRowClick = (evt: { data: SystemAdminFieldResponse }) => {
    navigate(`${SYS_ADMIN_ILAP_TERMS}/${evt.data.id}`);
  };

  const handleNewItemAddClick = () => {
    navigate(`${SYS_ADMIN_ILAP_TERM_NEW}`);
  };

  const toolbarConfig = {
    dislplaySearchPanel: true,
    displayAddNewButton: true,
    addNewButtonText: "New Item",
    onClickAddNew: handleNewItemAddClick,
  };

  return (
    <div className="flex justify-center">
      <PromineoViewGrid
        dataSource={props.fields}
        onRowClick={handleRowClick}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig}
        height={props.height}
      >
        <Scrolling rowRenderingMode={"virtual"} mode={"virtual"} />
        <Column
          caption={"Planning object type"}
          dataField="planningObjectTypesText"
          alignment="left"
        />
        <Column caption={"ILAP Term"} dataField="name" alignment="left" />
        <Column
          caption={"Description"}
          dataField="description"
          alignment="left"
        />
        <Column
          caption={"Data type"}
          dataField="dataTypeText"
          alignment="left"
        />
        <Column
          caption={"# of templates using ILAP term"}
          dataField="templateUsageCount"
          alignment="left"
        />
        <Column
          caption={"Content control"}
          dataField="contentControlText"
          alignment="left"
          cssClass="custom-control-padding ignore-row-click"
          cellRender={ContentControlComponent}
          allowFiltering={false}
        />
      </PromineoViewGrid>
    </div>
  );
}
