import LabelWithContent from "../../../components/common/LabelWithContent";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import LabelHolder from "components/common/LabelHolder";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { useEffect, useState } from "react";
import LabelResponse from "interfaces/response/LabelResponse";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";
import DateEditor from "./DateEditor";
import FrequencyEditor from "./FrequencyEditor";
import moment from "moment";

export interface IeaScheduleProps {
  ownerExchangeRole: ExchangeRoleEnum;
  frequencyType?: FrequencyType;
  onValueChange: (fieldName: string, value: any) => void;
  onStartDateChange: (value: string) => void;
  onExpirationDateChange: (value: string) => void;
  onCommentChange: (value: string) => void;
  onLabelIndentifiersChange: (values: number[]) => void;
  onPredecessorAgreementIdChange?: (value: any) => void;
  onLagMinutesChange?: (value: any) => void;
  onFrequencyTypeChange?: (value: FrequencyType) => void;
  onCronExpressionChange: (value: string) => void;
  startDate: string;
  expirationDate: string;
}

export default function IEAScheduling(props: IeaScheduleProps) {
  const { ownerExchangeRole, onValueChange } = props;

  const [labels, setLabels] = useState<LabelResponse[]>([]);

  const handleDeleteLabel = (label: LabelResponse) => {
    let filteredLabels = labels.filter(
      (l) => l.id !== label.id || l._key_ !== label._key_
    );

    setLabels(filteredLabels);
  };

  const handleAddNewLabel = (label: LabelResponse) => {
    setLabels([...labels, label]);
  };

  useEffect(() => {
    const labelIdentifiers = labels.map((label) => label.id);
    onValueChange("labelIdentifiers", labelIdentifiers);
    props.onLabelIndentifiersChange(labelIdentifiers);
  }, [labels]);

  return (
    <div className="space-y-4">
      <div className="flex gap-x-8 border-b pb-4 border-lightGray">
        <div className="space-y-4">
          <DateEditor
            width={328}
            title="Start date"
            defaultValue={props.startDate}
            onValueChange={props.onStartDateChange}
          />
          <DateEditor
            width={328}
            value={props.expirationDate}
            title="Expiration date"
            onValueChange={props.onExpirationDateChange}
            min={
              props.startDate
                ? moment(props.startDate).add(1, "day").toString()
                : undefined
            }
            disabled={!props.startDate}
          />
        </div>
        <FrequencyEditor
          ownerExchangeRole={props.ownerExchangeRole!}
          onValueChange={props.onValueChange!}
          onPredecessorAgreementIdChange={props.onPredecessorAgreementIdChange}
          onLagMinutesChange={props.onLagMinutesChange}
          onFrequencyTypeChange={props.onFrequencyTypeChange}
          onCronExpressionChange={props.onCronExpressionChange}
          frequencyType={props.frequencyType}
        />
      </div>

      <LabelWithContent
        label="IEA labels"
        content={
          <LabelHolder
            labels={labels}
            labelType={SupportedLabelEntity.ExchangeAgreement}
            allowAdd={true}
            allowDelete={true}
            onAddLabel={handleAddNewLabel}
            onDelete={handleDeleteLabel}
          />
        }
      />

      <LabelWithContent
        label="Comments"
        content={
          <PromineoMultilineTextEdit
            height={157}
            width={328}
            placeholder="Type..."
            className="whitespace-pre-line text-10px font-Inter default-background"
            onValueChanged={(e: any) => {
              onValueChange("comments", e.event?.target.value);
              props.onCommentChange(e.event?.target.value);
            }}
          />
        }
      />
    </div>
  );
}
