import Test from "Test";
import PrivateRouteGuard from "components/route-guards/PrivateRouteGuard";
import PublicOnlyRouteGuard from "components/route-guards/PublicOnlyRouteGuard";
import ActiveIEAEditor from "features/ieas/active-ieas/ActiveIEAEditor";
import ActiveIEAs from "features/ieas/active-ieas/ActiveIEAs";
import ArchiveIEAs from "features/ieas/archive-ieas/ArchiveIEAs";
import ActiveIEAViewer from "features/ieas/iea-viewer/ActiveIEAViewer";
import ArchiveIEAViewer from "features/ieas/iea-viewer/ArchiveIEAViewer";
import NewIEAViewer from "features/ieas/iea-viewer/NewIEAViewer";
import InactiveIEAEditor from "features/ieas/inactive-ieas/InactiveIEAEditor";
import NewIEAs from "features/ieas/inactive-ieas/NewIEAs";
import LandingPage from "features/landing/LandingPage";
import Configs from "features/setup/configs/Configs";
import EditConfig from "features/setup/configs/EditConfig";
import NewConfig from "features/setup/configs/NewConfig";
import ConfigViewer from "features/setup/configs/config-view/ConfigViewer";
import ConnectorEditor from "features/setup/connectors/ConnectorEditor";
import ConnectorViewer from "features/setup/connectors/ConnectorViewer";
import Connectors from "features/setup/connectors/Connectors";
import IlapTermEditor from "features/system-admin/ilap-terms/IlapTermEditor";
import IlapTerms from "features/system-admin/ilap-terms/IlapTerms";
import ViewIlapTerm from "features/system-admin/ilap-terms/ViewIlapTerm";
import Tenants from "features/system-admin/tenants/Tenants";
import SystemAdminUsers from "features/system-admin/users/SystemAdminUsers";
import EditField from "features/templates/edit-field/EditField";
import CopyTemplate from "features/templates/edit-template/CopyTemplate";
import EditTemplate from "features/templates/edit-template/EditTemplate";
import NewTemplate from "features/templates/edit-template/NewTemplate";
import Templates from "features/templates/template-list/Templates";
import FieldViewer from "features/templates/view-field/FieldViewer";
import ViewTemplate from "features/templates/view-template/ViewTemplate";
import AutonomousComponentServiceTokens from "features/tenant-admin/autonomous-components/components/AutonomousComponentServiceTokens";
import AutonomousComponents from "features/tenant-admin/autonomous-components/components/AutonomousComponents";
import ContentControl from "features/tenant-admin/content-control/ContentControl";
import ContentControlFieldEditor from "features/tenant-admin/content-control/ContentControlFieldEditor";
import ContentControlFieldViewer from "features/tenant-admin/content-control/ContentControlFieldViewer";
import IlapAnalytics from "features/tenant-admin/ilap-analytics/components/IlapAnalytics";
import IlapAnalyticsServiceTokens from "features/tenant-admin/ilap-analytics/components/IlapAnalyticsServiceTokens";
import TenantViewEdit from "features/tenant-admin/tenant/TenantViewEdit";
import TenantAdminUsers from "features/tenant-admin/users/TenantAdminUsers";
import { Route, Routes } from "react-router-dom";
import * as path from "shared/constants/RoutePathConstants";

export default function Main() {
  return (
    <Routes>
      <Route
        path={path.LANDING}
        element={
          <PublicOnlyRouteGuard>
            <LandingPage />
          </PublicOnlyRouteGuard>
        }
      ></Route>
      <Route
        path={path.TEMPLATE_EDIT}
        element={
          <PrivateRouteGuard>
            <EditTemplate />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.NEW_TEMPLATE}
        element={
          <PrivateRouteGuard>
            <NewTemplate />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.COPY_TEMPLATE}
        element={
          <PrivateRouteGuard>
            <CopyTemplate />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TEMPLATE_FIELD_VIEW}
        element={
          <PrivateRouteGuard>
            <FieldViewer />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TEMPLATE_VIEW}
        element={
          <PrivateRouteGuard>
            <ViewTemplate />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.NEW_IEAS}
        element={
          <PrivateRouteGuard>
            <NewIEAs />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.NEW_IEA_VIEW}
        element={
          <PrivateRouteGuard>
            <NewIEAViewer />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.ARCHIVE_IEAS}
        element={
          <PrivateRouteGuard>
            <ArchiveIEAs />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.ARCHIVE_IEA_VIEW}
        element={
          <PrivateRouteGuard>
            <ArchiveIEAViewer />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.ACTIVE_IEA}
        element={
          <PrivateRouteGuard>
            <ActiveIEAs />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.ACTIVE_IEA_VIEW}
        element={
          <PrivateRouteGuard>
            <ActiveIEAViewer />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.ACTIVE_IEA_EDIT}
        element={
          <PrivateRouteGuard>
            <ActiveIEAEditor />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.NEW_IEA_EDIT}
        element={
          <PrivateRouteGuard>
            <InactiveIEAEditor />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TEMPLATES}
        element={
          <PrivateRouteGuard>
            <Templates />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.CONNECTORS}
        element={
          <PrivateRouteGuard>
            <Connectors />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.CONNECTOR_VIEW}
        element={
          <PrivateRouteGuard>
            <ConnectorViewer />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.CONNECTOR_EDIT}
        element={
          <PrivateRouteGuard>
            <ConnectorEditor />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.CONFIG_NEW}
        element={
          <PrivateRouteGuard>
            <NewConfig />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.CONFIG_VIEW}
        element={
          <PrivateRouteGuard>
            <ConfigViewer />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.CONFIG_EDIT}
        element={
          <PrivateRouteGuard>
            <EditConfig />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.CONFIGS}
        element={
          <PrivateRouteGuard>
            <Configs />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TEST}
        element={
          <PublicOnlyRouteGuard>
            <Test />
          </PublicOnlyRouteGuard>
        }
      ></Route>
      {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
      <Route
        path={path.TEMPLATE_FIELD_EDIT}
        element={
          <PrivateRouteGuard>
            <EditField />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TENANT_ADMIN_TENANT}
        element={
          <PrivateRouteGuard>
            <TenantViewEdit />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TENANT_ADMIN_USERS}
        element={
          <PrivateRouteGuard>
            <TenantAdminUsers />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TENANT_ADMIN_FIELDS}
        element={
          <PrivateRouteGuard>
            <ContentControl />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TENANT_ADMIN_FIELD_VIEW}
        element={
          <PrivateRouteGuard>
            <ContentControlFieldViewer />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.TENANT_ADMIN_FIELD_EDIT}
        element={
          <PrivateRouteGuard>
            <ContentControlFieldEditor />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.SYS_ADMIN_ILAP_TERMS}
        element={
          <PrivateRouteGuard>
            <IlapTerms />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.SYS_ADMIN_ILAP_TERM_EDIT}
        element={
          <PrivateRouteGuard>
            <IlapTermEditor />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.SYS_ADMIN_ILAP_TERM_VIEW}
        element={
          <PrivateRouteGuard>
            <ViewIlapTerm />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.SYS_ADMIN_ILAP_TERM_NEW}
        element={
          <PrivateRouteGuard>
            <IlapTermEditor />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.SYS_ADMIN_TENANTS}
        element={
          <PrivateRouteGuard>
            <Tenants />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.SYS_ADMIN_USERS}
        element={
          <PrivateRouteGuard>
            <SystemAdminUsers />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.AUTONOMOUS_COMPONENTS}
        element={
          <PrivateRouteGuard>
            <AutonomousComponents />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.AUTONOMOUS_COMPONENTS_SERVICE_TOKENS}
        element={
          <PrivateRouteGuard>
            <AutonomousComponentServiceTokens />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.ILAP_ANALYTICS}
        element={
          <PrivateRouteGuard>
            <IlapAnalytics />
          </PrivateRouteGuard>
        }
      ></Route>
      <Route
        path={path.ILAP_ANALYTICS_SERVICE_TOKENS}
        element={
          <PrivateRouteGuard>
            <IlapAnalyticsServiceTokens />
          </PrivateRouteGuard>
        }
      ></Route>
    </Routes>
  );
}
