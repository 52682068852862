import GridRowStatusLabel from "components/common/GridRowStatusLabel";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";

interface Props {
  data: { data: ActiveExchangeAgreementListingResponse };
}

export default function TitleCellComponent(props: Props) {
  let activeIeaResponse = props.data.data;
  // change label bg and text based on iea status
  let cssClassForBg = "bg-lightGreen";
  let text = "Update available";

  return (
    <div className="flex justify-between gap-x-2 items-center">
      <div className="flex-shrink min-w-0 overflow-hidden" title={activeIeaResponse.title}>
        {activeIeaResponse.title}
      </div>
      <div className="min-w-fit ">
        {activeIeaResponse.updateRequired && (
          <GridRowStatusLabel text={text} cssClasses={cssClassForBg} />
        )}
      </div>
    </div>
  );
}
