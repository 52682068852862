import AddNewButton from "components/common/controls/buttons/AddNewButton";
import PromineoEditGrid from "components/common/grid/PromineoEditGrid";
import DataGrid, {
  Column,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";
import SafranStructureImportMappingConfig from "interfaces/host-system-config/safran/SafranStructureImportMappingConfig";
import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useCallback,
  useMemo,
} from "react";
import { GridRowKeyProperty } from "shared/constants/GridConstants";
import { EditorMode } from "shared/enums/EditorMode";
import { GetNewId } from "shared/utilities/CommonUtility";
import RFieldDropdownCellEdit from "./RFieldDropdownCellEdit";
import HostFieldResponse from "interfaces/response/HostFieldResponse";

interface Props {
  height: number;
  mode: EditorMode;
  structureImportMappings: SafranStructureImportMappingConfig[];
  selectedCodeSet: number | null;
  fullWidth?: boolean;
  hostFields: HostFieldResponse[];
  onStructureGridModified?: () => void;
}

const SafranStructureImportMappingGrid = forwardRef(
  (props: Props, forwardedRef) => {
    const { hostFields } = props;
    const isEditMode = props.mode === EditorMode.Edit;
    const gridRef = useRef<DataGrid>(null);
    const [rFields, setRFields] = useState<HostFieldResponse[]>([]);

    useEffect(() => {
      const rFieldNamePattern = /^uf_ref\d+$/;
      const filteredRFields =
        hostFields?.filter(
          (hf) =>
            hf.userFieldSetId === props.selectedCodeSet &&
            rFieldNamePattern.test(hf.name)
        ) || [];
      setRFields(filteredRFields);
    }, [hostFields, props.selectedCodeSet]);

    const [importMappings, setImportMappings] = useState<
      SafranStructureImportMappingConfig[]
    >([]);

    const handleIsModified = useCallback(() => {
      props.onStructureGridModified?.();
    }, []);

    useEffect(() => {
      const processedMappingForGrid = (props.structureImportMappings || []).map(
        (mapping) => {
          return { ...mapping, [GridRowKeyProperty]: GetNewId() };
        }
      );
      setImportMappings(processedMappingForGrid);
    }, [props.structureImportMappings]);

    const handleAddNewClick = () => {
      setImportMappings((prev) => {
        return [
          ...prev,
          {
            structureName: "",
            rFieldName: "",
            [GridRowKeyProperty]: GetNewId(),
          },
        ];
      });
      handleIsModified();
    };

    useImperativeHandle(
      forwardedRef,
      () => ({
        getStructureMappings() {
          return gridRef.current?.instance
            .getDataSource()
            .items()
            .map((mapping) => {
              let updatedMapping = { ...mapping };
              delete updatedMapping[GridRowKeyProperty];
              return updatedMapping;
            }) as SafranStructureImportMappingConfig[];
        },
      }),
      []
    );

    const RFieldDropdownMemoized = useCallback(
      (data: any) => {
        return (
          <RFieldDropdownCellEdit
            data={data}
            mode={props.mode}
            rFields={rFields}
            onValueChange={handleIsModified}
          />
        );
      },
      [props.mode, rFields]
    );

    const deleteRowOptions = useMemo(() => {
      const handleDelete = (data: any) => {
        setImportMappings((prev) => {
          var mappingsWithoutDeletedOne = prev.filter(
            (mapping: any) =>
              mapping[GridRowKeyProperty] !== data.data[GridRowKeyProperty]
          );
          return mappingsWithoutDeletedOne;
        });
        handleIsModified();
      };

      return {
        visible: props.mode === EditorMode.Edit,
        onDelete: handleDelete,
      };
    }, [props.mode]);

    const gridHeight = useMemo(() => {
      const allocatedHeightForAddNewButton = 42;
      return (
        props.height -
        (props.mode === EditorMode.Edit ? allocatedHeightForAddNewButton : 0)
      );
    }, [props.mode, props.height]);

    return (
      <div>
        {isEditMode && (
          <div className="flex justify-end mb-2">
            <AddNewButton onClick={handleAddNewClick} />
          </div>
        )}
        <PromineoEditGrid
          className={props.mode === EditorMode.View ? "view-only" : ""}
          displayDeleteRowOption={deleteRowOptions}
          ref={gridRef}
          width={props.fullWidth ? "100%" : 800}
          height={gridHeight}
          dataSource={importMappings}
          keyExpr={GridRowKeyProperty}
          onRowUpdated={handleIsModified}
        >
          <Scrolling mode={"standard"} />
          {props.mode === EditorMode.Edit ? (
            <Editing mode={"cell"} allowUpdating={true} />
          ) : null}
          <Column
            caption={"Structure"}
            showEditorAlways={isEditMode}
            dataField={"structureName"}
          />
          <Column
            caption={"R-Field"}
            dataField={"rFieldName"}
            allowEditing={false}
            cellRender={RFieldDropdownMemoized}
          />
        </PromineoEditGrid>
      </div>
    );
  }
);

export default SafranStructureImportMappingGrid;
