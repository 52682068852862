import { HostSystem } from "shared/enums/feature/HostSystem";
import { getValueFromJsonString } from "./JsonUtility";
import AnalyticsExportConfig from "interfaces/host-system-config/analytics/AnalyticsExportConfig";
import AnalyticsImportConfig from "interfaces/host-system-config/analytics/AnalyticsImportConfig";
import SapExportConfig from "interfaces/host-system-config/sap/SapExportConfig";
import { SapImportConfig } from "interfaces/host-system-config/sap/SapImportConfig";
import MicrosoftExcelExportConfig from "interfaces/host-system-config/MicrosoftExcelExportConfig";
import MicrosoftExcelImportConfig from "interfaces/host-system-config/MicrosoftExcelImportConfig";
import MicrosoftProjectExportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectExportConfig";
import MicrosoftProjectImportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectImportConfig";
import PrimaveraExportConfig from "interfaces/host-system-config/primavera/PrimaveraExportConfig";
import PrimaveraImportConfig from "interfaces/host-system-config/primavera/PrimaveraImportConfig";
import { PrimaveraActivityLinkRebuildOption } from "shared/enums/feature/PrimaveraActivityLinkRebuildOption";
import SafranBaseExportConfig from "interfaces/host-system-config/safran/SafranBaseExportConfig";
import { RevisionType } from "shared/enums/RevisionTypeEnum";
import { SapExportFilterType } from "shared/enums/feature/sap-host-system/SapExportFilterType";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import SafranBaseImportConfig from "interfaces/host-system-config/safran/SafranBaseImportConfig";

interface ExchangeParameterConfigs {
  export: string;
  import: string;
}

const microsoftExcelExportParameter: MicrosoftExcelExportConfig = {};
const microsoftExcelImportParameter: MicrosoftExcelImportConfig = {};

const safranImportParameter: SafranBaseImportConfig = {
  activityFilter: "",
  calendarPrefix: "",
};

const safranExportParameter: SafranBaseExportConfig = {
  activityFilter: "",
  exportScope: 0,
};

const analyticsExportParameter: AnalyticsExportConfig = {
  activityFilter: "",
  revisionType: RevisionType.Baseline,
};

const analyticsImportParameter: AnalyticsImportConfig = {
  revisionType: RevisionType.Baseline,
};

const microsoftProjectExportParameter: MicrosoftProjectExportConfig = {
  activityFilter: "",
  includeSummaryTask: false,
  wbsStructureExportFieldName: ""
};
const microsoftProjectImportParameter: MicrosoftProjectImportConfig = {};

const primaveraExportParameter: PrimaveraExportConfig = {
  activityFilter: "",
  exportAllStructures: false,
};
const primaveraImportParameter: PrimaveraImportConfig = {
  activityFilter: "",
  epsId: "",
  importAsNewProject: false,
  allowAppendingTasks: true,
  allowAppendResource: true,
  allowAppendingCalendars: true,
  allowAppendingStructures: true,
  doNotImportWbs: false,
  activityLinkRebuildOption:
    PrimaveraActivityLinkRebuildOption.RebuildIfBothActivitiesExist,
};

const sapExportParameter: SapExportConfig = {
  orderFilter: "",
  operationFilter: "",
  exportFilterType: SapExportFilterType.OrderFilter,
};

const sapImportParameter: SapImportConfig = {
  functions: [],
  stopOnError: false,
  includeMapping: 1,
};

const ExchangeParameterConfig: Record<HostSystem, ExchangeParameterConfigs> = {
  [HostSystem.None]: { export: "", import: "" },
  [HostSystem.MicrosoftExcel]: {
    export: JSON.stringify(microsoftExcelExportParameter),
    import: JSON.stringify(microsoftExcelImportParameter),
  },
  [HostSystem.Safran]: {
    export: JSON.stringify(safranExportParameter),
    import: JSON.stringify(safranImportParameter),
  },
  [HostSystem.IlapAnalytics]: {
    export: JSON.stringify(analyticsExportParameter),
    import: JSON.stringify(analyticsImportParameter),
  },
  [HostSystem.MicrosoftProject]: {
    export: JSON.stringify(microsoftProjectExportParameter),
    import: JSON.stringify(microsoftProjectImportParameter),
  },
  [HostSystem.PrimaveraP6]: {
    export: JSON.stringify(primaveraExportParameter),
    import: JSON.stringify(primaveraImportParameter),
  },
  [HostSystem.SAP]: {
    export: JSON.stringify(sapExportParameter),
    import: JSON.stringify(sapImportParameter),
  },
};

export const getExchangeParameterConfig = (hostSystem: HostSystem) => {
  var parameters = { ...ExchangeParameterConfig[hostSystem] };

  return parameters;
};

export const getExchangeConfigPropertyValue = (
  configJsonString: string,
  propertyName: string
) => {
  return getValueFromJsonString(configJsonString, propertyName);
};

export const shouldDisplayExchangeParametersTab = (
  hostSystem?: HostSystem
): boolean => {
  var shouldDisplay = true;

  if (hostSystem === null || hostSystem === undefined) {
    shouldDisplay = false;
  } else {
    if (
      hostSystem === HostSystem.Safran ||
      hostSystem === HostSystem.IlapAnalytics
    ) {
      shouldDisplay = false;
    }
  }

  return shouldDisplay;
};

export const getScheduleLabel = (
  connectorSchedule: ConnectorScheduleSimplifiedResponse
): string => {
  var scheduleTitle = "-";

  if (connectorSchedule) {
    scheduleTitle = `Id: ${connectorSchedule.scheduleId} - ${connectorSchedule.title}`;
  }

  return scheduleTitle;
};

export const getDisplayExpressionForScheduleSelectorDropdown = (
  connectorSchedule: ConnectorScheduleSimplifiedResponse
): string => {
  return (
    connectorSchedule &&
    `Id: ${connectorSchedule.scheduleId} - ${connectorSchedule.title}`
  );
};
