import { createSlice } from "@reduxjs/toolkit";
import DropdownValuesWithExecutionComponentResponse from "interfaces/response/DropdownValuesWithExecutionComponentResponse";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import {
  loadAnalyticsConnectorAuthenticationModeValuesAsync,
  loadAnalyticsRevisionTypeValuesAsync,
  loadApplicationRoleValuesAsync,
  loadContentControlValuesAsync,
  loadDataTypeValuesAsync,
  loadExecutionComponentValuesAsync,
  loadFieldContentControlLevelAsync,
  loadHostSystemValuesAsync,
  loadPlanningObjectTypeValuesAsync,
  loadSAPConnectorAuthenticationModeValuesAsync,
  loadPrimaveraConnectorAuthenticationModeValuesAsync,
  loadSafranApiConnectorAuthenticationModeValuesAsync,
  loadStorageAtHomeOptionValuesAsync,
  loadTenantContentControlLavelValuesAsync,
  loadExportScopeValuesAsync,
  loadRFieldExportOptionValuesAsync,
  loadActivityLinkRebuildOptionValuesAsync,
  loadRevisionTypeOptionValuesAsync,
  loadSapObjectTypeOptionValuesAsync,
  loadSapFunctionTypeOptionValuesAsync,
  loadSapFunctionContextOptionValuesAsync,
  loadSapSequenceTypeOptionValuesAsync,
  loadConflictResolveApproachValuesAsync,
} from "store/actions/DropdownValueActions";

interface DropdownValueState {
  executionComponent: DropdownValuesResponse[];
  hostSystem: DropdownValuesWithExecutionComponentResponse[];
  contentControls: DropdownValuesResponse[];
  tenantContentControlsLevels: DropdownValuesResponse[];
  safranApiConnectorAuthenticationMode: DropdownValuesWithExecutionComponentResponse[];
  analyticsConnectorAuthenticationMode: DropdownValuesWithExecutionComponentResponse[];
  sapConnectorAuthenticationMode: DropdownValuesWithExecutionComponentResponse[];
  primaveraConnectorAuthenticationMode: DropdownValuesWithExecutionComponentResponse[];
  analyticsRevisionTypes: DropdownValuesResponse[];
  planningObjectTypes: DropdownValuesResponse[];
  fieldContentControlLevels: DropdownValuesResponse[];
  applicationRoles: DropdownValuesResponse[];
  storageAtHomeOptions: DropdownValuesResponse[];
  dataTypes: DropdownValuesResponse[];
  exportScopes: DropdownValuesResponse[];
  rFieldExportOptions: DropdownValuesResponse[];
  activityLinkRebuildOption: DropdownValuesResponse[];
  revisionTypeOptions: DropdownValuesResponse[];
  sapObjectTypes: DropdownValuesResponse[];
  sapFunctionTypes: DropdownValuesResponse[];
  sapContextTypes: DropdownValuesResponse[];
  sapSequenceTypes: DropdownValuesResponse[];
  conflictResolveApproaches: DropdownValuesResponse[];
}

const initialState: DropdownValueState = {
  executionComponent: [],
  hostSystem: [],
  contentControls: [],
  safranApiConnectorAuthenticationMode: [],
  analyticsConnectorAuthenticationMode: [],
  sapConnectorAuthenticationMode: [],
  primaveraConnectorAuthenticationMode: [],
  analyticsRevisionTypes: [],
  planningObjectTypes: [],
  fieldContentControlLevels: [],
  tenantContentControlsLevels: [],
  applicationRoles: [],
  storageAtHomeOptions: [],
  dataTypes: [],
  exportScopes: [],
  rFieldExportOptions: [],
  activityLinkRebuildOption: [],
  revisionTypeOptions: [],
  sapObjectTypes: [],
  sapFunctionTypes: [],
  sapContextTypes: [],
  sapSequenceTypes: [],
  conflictResolveApproaches: [],
};

export const dropdownValuesSlice = createSlice({
  name: "dropdownValues",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadExecutionComponentValuesAsync.fulfilled,
      (state, action) => {
        state.executionComponent = action.payload;
      }
    );

    builder.addCase(loadHostSystemValuesAsync.fulfilled, (state, action) => {
      state.hostSystem = action.payload;
    });

    builder.addCase(
      loadSafranApiConnectorAuthenticationModeValuesAsync.fulfilled,
      (state, action) => {
        state.safranApiConnectorAuthenticationMode = action.payload;
      }
    );

    builder.addCase(
      loadAnalyticsConnectorAuthenticationModeValuesAsync.fulfilled,
      (state, action) => {
        state.analyticsConnectorAuthenticationMode = action.payload;
      }
    );

    builder.addCase(
      loadSAPConnectorAuthenticationModeValuesAsync.fulfilled,
      (state, action) => {
        state.sapConnectorAuthenticationMode = action.payload;
      }
    );

    builder.addCase(
      loadPrimaveraConnectorAuthenticationModeValuesAsync.fulfilled,
      (state, action) => {
        state.primaveraConnectorAuthenticationMode = action.payload;
      }
    );

    builder.addCase(
      loadAnalyticsRevisionTypeValuesAsync.fulfilled,
      (state, action) => {
        state.analyticsRevisionTypes = action.payload;
      }
    );

    builder.addCase(
      loadContentControlValuesAsync.fulfilled,
      (state, action) => {
        state.contentControls = action.payload;
      }
    );

    builder.addCase(
      loadPlanningObjectTypeValuesAsync.fulfilled,
      (state, action) => {
        state.planningObjectTypes = action.payload;
      }
    );

    builder.addCase(loadDataTypeValuesAsync.fulfilled, (state, action) => {
      state.dataTypes = action.payload;
    });

    builder.addCase(
      loadFieldContentControlLevelAsync.fulfilled,
      (state, action) => {
        state.fieldContentControlLevels = action.payload;
      }
    );

    builder.addCase(
      loadTenantContentControlLavelValuesAsync.fulfilled,
      (state, action) => {
        state.tenantContentControlsLevels = action.payload;
      }
    );

    builder.addCase(
      loadApplicationRoleValuesAsync.fulfilled,
      (state, action) => {
        state.applicationRoles = action.payload;
      }
    );

    builder.addCase(
      loadStorageAtHomeOptionValuesAsync.fulfilled,
      (state, action) => {
        state.storageAtHomeOptions = action.payload;
      }
    );

    builder.addCase(loadExportScopeValuesAsync.fulfilled, (state, action) => {
      state.exportScopes = action.payload;
    });

    builder.addCase(
      loadRFieldExportOptionValuesAsync.fulfilled,
      (state, action) => {
        state.rFieldExportOptions = action.payload;
      }
    );

    builder.addCase(
      loadActivityLinkRebuildOptionValuesAsync.fulfilled,
      (state, action) => {
        state.activityLinkRebuildOption = action.payload;
      }
    );

    builder.addCase(
      loadRevisionTypeOptionValuesAsync.fulfilled,
      (state, action) => {
        state.revisionTypeOptions = action.payload;
      }
    );

    builder.addCase(
      loadSapObjectTypeOptionValuesAsync.fulfilled,
      (state, action) => {
        state.sapObjectTypes = action.payload;
      }
    );

    builder.addCase(
      loadSapFunctionTypeOptionValuesAsync.fulfilled,
      (state, action) => {
        state.sapFunctionTypes = action.payload;
      }
    );

    builder.addCase(
      loadSapFunctionContextOptionValuesAsync.fulfilled,
      (state, action) => {
        state.sapContextTypes = action.payload;
      }
    );

    builder.addCase(
      loadSapSequenceTypeOptionValuesAsync.fulfilled,
      (state, action) => {
        state.sapSequenceTypes = action.payload;
      }
    );

    builder.addCase(
      loadConflictResolveApproachValuesAsync.fulfilled,
      (state, action) => {
        state.conflictResolveApproaches = action.payload;
      }
    );
  },
});

export default dropdownValuesSlice.reducer;
