import ConflictsGrid from "./ConflictsGrid";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import { useEffect, useState } from "react";
import {
  loadDownloadConflictsAsync,
  saveConflictResolveApproachAsync,
} from "store/actions/ConflictActoins";
import { loadConflictResolveApproachValuesAsync } from "store/actions/DropdownValueActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetDownloadConflicts } from "store/slices/ConflictSlice";
import { resetDataTransferConflictStatus } from "store/slices/ExchangeAgreementSlice";

interface Props {
  iea: ActiveExchangeAgreementListingResponse;
  onClose: () => void;
}

// The conflict resolve options are coming from the backend.
// For "AcceptIncoming" the value is 3. For that reason, we are using a constant value to
// represent that option.
const acceptIncomingChangesInConflictResolveOptionValue = 3;

export default function DownloadConflictsViewer(props: Props) {
  const dispatch = useAppDispatch();
  const [selectedConflictResolveApproach, setSelectedConflictResolveApproach] =
    useState<undefined | number>();

  const [
    availableConflictResolveApproaches,
    setAvailableConflictResolveApproaches,
  ] = useState<DropdownValuesResponse[]>([]);

  useEffect(() => {
    if (props.iea.lastTransferId) {
      displayLoadingPanel();
      dispatch(loadDownloadConflictsAsync(props.iea.lastTransferId)).finally(
        hideLoadingPanel
      );

      displayLoadingPanel();
      dispatch(loadConflictResolveApproachValuesAsync()).finally(
        hideLoadingPanel
      );
    }

    return () => {
      dispatch(resetDownloadConflicts());
    };
  }, []);

  const downloadConflicts = useAppSelector(
    (store) => store.conflictData.downloadConflicts
  );

  const conflictResolveApproaches = useAppSelector(
    (store) => store.dropdownData.conflictResolveApproaches
  );

  useEffect(() => {
    // If in the conflict list, we do not have any conflicts that can be solved
    // automatically, then we will not display the "AcceptIncoming" option.
    // For this reason, before displaying the conflict resolve options, we are
    // checking if there exists any conflict that can be resolved automatically.
    if (
      downloadConflicts &&
      downloadConflicts.some((c) => c.canBeResolvedAutomatically)
    ) {
      setAvailableConflictResolveApproaches(conflictResolveApproaches);
    } else {
      var filteredConflictResolveApproach = conflictResolveApproaches.filter(
        (rs) => rs.value != acceptIncomingChangesInConflictResolveOptionValue
      );
      setAvailableConflictResolveApproaches(filteredConflictResolveApproach);
    }
  }, [downloadConflicts]);

  const handleCloseClick = () => {
    props.onClose();
  };

  const handleConflictResolveApproachChange = (resolveApproach: number) => {
    setSelectedConflictResolveApproach(resolveApproach);
  };

  const handleConfirmClick = () => {
    if (selectedConflictResolveApproach && props.iea.lastTransferId) {
      displayLoadingPanel();
      dispatch(
        saveConflictResolveApproachAsync({
          dataTransferId: props.iea.lastTransferId,
          conflictResolveRequest: {
            resolveOption: selectedConflictResolveApproach,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(resetDataTransferConflictStatus(props.iea.id));
          props.onClose();
        })
        .finally(hideLoadingPanel);
    }
  };

  return (
    <div className="space-y-4">
      <div className="font-bold">
        Download conflicts - {props.iea.id} {props.iea.title}
      </div>
      <ConflictsGrid downloadConflicts={downloadConflicts} />
      <div className="flex justify-between">
        <div>
          <PromineoButton
            text="Close"
            onClick={handleCloseClick}
            variant={PromineoButtonType.Secondary}
          />
        </div>
        <div className="flex gap-x-2 items-center">
          <PromineoSelectBox
            width={414}
            displayExpr={"name"}
            valueExpr={"value"}
            placeholder="Select action"
            items={availableConflictResolveApproaches}
            onValueChange={handleConflictResolveApproachChange}
          />
          <PromineoButton
            text="Confirm"
            onClick={handleConfirmClick}
            disabled={!selectedConflictResolveApproach}
          />
        </div>
      </div>
    </div>
  );
}
