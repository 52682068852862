import { getTokenAsync } from "authentication/AuthenticationManager";
import { parseErrorResponse } from "./ErrorParser";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getApiUrl } from "shared/utilities/ConfigUtility";

export function WithInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

export function WithAuthenticationInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (
      config.baseURL === getApiUrl() &&
      !(config && config.headers && config.headers.Authorization)
    ) {
      const token = await getTokenAsync();
      if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

function handleSuccessfulRequest(response: AxiosResponse<any>) {
  return response;
}

function handleFailedRequest(error: AxiosResponse<any>) {
  const parsedError = parseErrorResponse(error);
  return Promise.reject(parsedError);
}
