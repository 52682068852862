export default function OverdueIcon() {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.88889 7.55556V13.1111L13.6111 15.9111L14.4667 14.4889L10.5556 12.1667V7.55556H8.88889ZM15.5 6.5L17.0667 4.93333C15.2667 3.12222 12.7667 2 10 2C4.47778 2 0 6.47778 0 12C0 17.5222 4.47778 22 10 22C15.5222 22 20 17.5222 20 12H17.7778C17.7778 16.2889 14.2889 19.7778 10 19.7778C5.71111 19.7778 2.22222 16.2889 2.22222 12C2.22222 7.71111 5.71111 4.22222 10 4.22222C12.1444 4.22222 14.0889 5.1 15.5 6.5Z"
        fill="#FF9A3A"
      />
      <rect x="12" y="1" width="13" height="13" rx="6.5" fill="#FF9A3A" />
      <rect
        x="12"
        y="1"
        width="13"
        height="13"
        rx="6.5"
        stroke="white"
        stroke-width="2"
      />
      <rect x="17.5" y="3.5" width="2" height="4" fill="white" />
      <rect x="17.5" y="9.5" width="2" height="2" fill="white" />
    </svg>
  );
}
