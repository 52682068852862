import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import SafranImportConfig from "interfaces/host-system-config/safran/SafranImportConfig";
import { forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { EditorMode } from "shared/enums/EditorMode";
import { loadActivityLinkRebuildOptionValuesAsync } from "store/actions/DropdownValueActions";
import { AppDispatch } from "store/store";
import { ScrollView } from "devextreme-react";
import SafranStructureImportMappingGrid from "./SafranStructureImportMappingGrid";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import SafranReceivingParaeterFields from "./SafranReceivingParameterFields";

interface Props {
  mode: EditorMode;
  height: number;
  selectedCodeSet: number | null;
  importConfig: SafranImportConfig;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
  hostFields: HostFieldResponse[];
  onStructureGridModified?: () => void;
}

const SafranReceivingParameters = forwardRef((props: Props, forwardedRef) => {
  const {
    mode,
    height,
    importConfig,
    handleConfigurationValueChanged,
    hostFields,
    isOverride,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (importConfig) {
      displayLoadingPanel();
      dispatch(loadActivityLinkRebuildOptionValuesAsync()).finally(
        hideLoadingPanel
      );
    }
  }, [dispatch, importConfig]);

  if (!importConfig) {
    return <></>;
  }

  return !isOverride ? (
    <ScrollView showScrollbar="always" height={height}>
      <div className="flex space-x-6">
        <div className="w-60 font-semibold text-base text-textGray">
          Safran Receiving Parameter
        </div>
        <div className="space-y-6">
          <SafranReceivingParaeterFields
            handleConfigurationValueChanged={handleConfigurationValueChanged}
            importConfig={importConfig}
            mode={mode}
          />
        </div>
        <div>
          <SafranStructureImportMappingGrid
            height={height}
            mode={mode}
            structureImportMappings={props.importConfig.structureImportMappings}
            ref={forwardedRef}
            selectedCodeSet={props.selectedCodeSet}
            hostFields={hostFields || []}
          />
        </div>
      </div>
    </ScrollView>
  ) : (
    <div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-8">
        <SafranReceivingParaeterFields
          handleConfigurationValueChanged={handleConfigurationValueChanged}
          importConfig={importConfig}
          mode={mode}
        />
      </div>
      <div className="mt-4">
        <SafranStructureImportMappingGrid
          height={height}
          fullWidth={true}
          mode={mode}
          structureImportMappings={props.importConfig.structureImportMappings}
          ref={forwardedRef}
          selectedCodeSet={props.selectedCodeSet}
          hostFields={hostFields || []}
          onStructureGridModified={props.onStructureGridModified}
        />
      </div>
    </div>
  );
});

export default SafranReceivingParameters;
