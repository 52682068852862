import { StepFourNewIeaProps } from "features/ieas/active-ieas/new-iea/StepFourNewIEA";
import PrimaveraExportConfig from "interfaces/host-system-config/primavera/PrimaveraExportConfig";
import SafranImportConfig from "interfaces/host-system-config/safran/SafranImportConfig";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import {
  getHostSystemConfigurationValue,
  getSapSendingDefaultConfiguration,
} from "shared/utilities/HostSystemConfigurationUtility";

export interface StepFourDataHookProps {
  direction: ConfigDirection;
  hostSystemParameters?: string;
  codeSet?: number;
  connectorId?: number;
  hostSystem?: HostSystem;
}

interface HookResponseType {
  stepFourProps: StepFourNewIeaProps;
  hostParameterRequest?: SupportedHostSystemConfigurationType;
  isHostParameterRequestValid: boolean;
  getUpdatedHostParametersForSaving: (
    updatedParameter?: SupportedHostSystemConfigurationType
  ) => SupportedHostSystemConfigurationType | undefined;
}

const defaultPrimaveraExportParameters: PrimaveraExportConfig = {
  activityFilter: "",
  exportAllStructures: false,
};

export default function useStepFourNewIeaDataHook(
  props: StepFourDataHookProps
): HookResponseType {
  const gridRef = useRef<any>();
  const [isValidRequest, setIsValidRequest] = useState(false);

  const [exportOrImportParameters, setExportOrImportParameters] =
    useState<SupportedHostSystemConfigurationType>();

  useEffect(() => {
    let isValid = true;
    setIsValidRequest(isValid);
  }, [exportOrImportParameters]);

  useEffect(() => {
    if (props.connectorId && props.hostSystem) {
      var hostParameters: SupportedHostSystemConfigurationType | undefined;
      if (
        props.direction === ConfigDirection.Sending &&
        props.hostSystem === HostSystem.SAP
      ) {
        hostParameters = getSapSendingDefaultConfiguration();
      } else if (
        props.direction === ConfigDirection.Sending &&
        props.hostSystem === HostSystem.PrimaveraP6
      ) {
        hostParameters = defaultPrimaveraExportParameters;
      } else if (props.hostSystemParameters) {
        hostParameters =
          getHostSystemConfigurationValue(
            props.hostSystem,
            props.direction,
            props.hostSystemParameters
          ) ?? undefined;
      }
      setExportOrImportParameters(hostParameters);
    }
  }, [
    props.hostSystemParameters,
    props.connectorId,
    props.hostSystem,
    props.direction,
  ]);

  const handleHostParameterValueChanged = (
    fieldName: string,
    value: string
  ) => {
    setExportOrImportParameters((prev) => {
      var updatedParameter = prev ? { ...prev, [fieldName]: value } : undefined;
      return updatedParameter;
    });
  };

  const getUpdatedHostParametersForSaving = useCallback(
    (updatedParameter?: SupportedHostSystemConfigurationType) => {
      let updatedExportParameter = cloneDeep(
        updatedParameter ?? exportOrImportParameters
      );
      if (
        gridRef &&
        gridRef.current &&
        props.direction === ConfigDirection.Receiving
      ) {
        if (props.hostSystem === HostSystem.SAP) {
          if (gridRef.current.getUpdateSapHostParameters) {
            updatedExportParameter =
              gridRef.current.getUpdateSapHostParameters();
          }
        } else if (props.hostSystem === HostSystem.Safran) {
          if (gridRef.current.getStructureMappings) {
            const structureImportMappings =
              gridRef.current.getStructureMappings();
            updatedExportParameter = {
              ...updatedExportParameter,
              structureImportMappings,
            } as SafranImportConfig;
          }
        }
      }

      return updatedExportParameter;
    },
    [exportOrImportParameters]
  );

  const response = useMemo<HookResponseType>(() => {
    return {
      stepFourProps: {
        hostParameterOverrideProps: {
          ref: gridRef,
          isNew: true,
          gridHeight: props.hostSystem === HostSystem.Safran ? 275 : 400,
          isEditable: true,
          isCurrentUserReceiver: props.direction === ConfigDirection.Receiving,
          isCurrentUserSender: props.direction === ConfigDirection.Sending,
          receiverCodeSet:
            props.direction === ConfigDirection.Receiving
              ? props.codeSet
              : null,
          senderCodeSet:
            props.direction === ConfigDirection.Sending ? props.codeSet : null,
          receiverConnectorId:
            props.direction === ConfigDirection.Receiving
              ? props.connectorId
              : undefined,
          senderConnectorId:
            props.direction === ConfigDirection.Sending
              ? props.connectorId
              : undefined,
          exportConfig:
            props.direction === ConfigDirection.Receiving
              ? undefined
              : exportOrImportParameters,
          importConfig:
            props.direction === ConfigDirection.Sending
              ? undefined
              : exportOrImportParameters,
          displayIfConfigIsAvailable: true,
          senderHostSystem: props.hostSystem,
          receiverHostSystem: props.hostSystem,
          onReceiverConfigurationValueChanged: handleHostParameterValueChanged,
          onSenderConfigurationValueChanged: handleHostParameterValueChanged,
        },
      },
      isHostParameterRequestValid: isValidRequest,
      hostParameterRequest: exportOrImportParameters,
      getUpdatedHostParametersForSaving,
    };
  }, [
    props,
    isValidRequest,
    exportOrImportParameters,
    getUpdatedHostParametersForSaving,
  ]);

  return response;
}
