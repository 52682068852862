import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";

interface Props {
  data: { data: FieldMappingResponse };
  onSelectionChagne: (isChecked: boolean) => void;
}

export default function DirectMappingToggleButton(props: Props) {
  const handleChange = () => {
    props.onSelectionChagne(!props.data.data.hasDirectMapping);
  };

  return (
    <PromineoSwitchButton
      checked={props.data.data.hasDirectMapping}
      switchType={SwitchButtonType.Default}
      onChange={handleChange}
    />
  );
}
