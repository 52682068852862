import "./styles/PromineoBaseGrid.css";
import DataGrid, {
  Column,
  IDataGridOptions,
  LoadPanel,
} from "devextreme-react/data-grid";
import React, { useCallback } from "react";
import CrossButton from "../controls/buttons/CrossButton";

export interface RowDeletionOption {
  visible?: boolean;
  canDeleteRowFn?: (data: any) => boolean;
  onDelete: (data: any) => void;
}

interface Props {
  refference?: React.LegacyRef<DataGrid<any, any>>;
  rowDeletoinOption?: RowDeletionOption;
}

function PromineoBaseGrid(gridOptions: Props & IDataGridOptions) {
  const { className, refference, ...rest } = gridOptions;

  const DeleteButtonComponent = useCallback(
    (data: any) => {
      var shouldDisplayDeleteIcon =
        gridOptions.rowDeletoinOption?.canDeleteRowFn?.(data) ?? true;

      return shouldDisplayDeleteIcon ? (
        <>
          <div>
            <CrossButton
              classNames={`delete-row-button w-18px`}
              onDeleteClick={() => gridOptions.rowDeletoinOption?.onDelete(data)}
            />
          </div>
        </>
      ) : (
        <></>
      );
    },
    [gridOptions.rowDeletoinOption]
  );

  return (
    <DataGrid
      ref={refference}
      {...rest}
      showBorders={false}
      showColumnLines={false}
      showRowLines={false}
      className={`promineo-base-grid ${className}`}
    >
      <LoadPanel enabled={false} />
      {gridOptions.children}
      <Column
        cssClass={"grid-row-delete-option ignore-row-click"}
        visible={gridOptions.rowDeletoinOption?.visible === true}
        width={35}
        minWidth={35}
        alignment="left"
        cellRender={DeleteButtonComponent}
      ></Column>
    </DataGrid>
  );
}

export default React.forwardRef(
  (
    props: IDataGridOptions & Props,
    ref: React.LegacyRef<DataGrid<any, any>>
  ) => {
    return <PromineoBaseGrid {...props} refference={ref} />;
  }
);
