import PromineoEditGrid from "components/common/grid/PromineoEditGrid";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
} from "devextreme-react/data-grid";
import { SapImportConfigFunction } from "interfaces/host-system-config/sap/SapImportConfig";
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import ObjectTypeDropdownCellEdit from "./ObjectTypeDropdownCellEdit";
import FunctionTypeDropdownCellEdit from "./FunctionTypeDropdownCellEdit";
import { SapHostParameterGridKey } from "shared/utilities/HostSystemConfigurationUtility";
import FunctionContextDropdownCellEdit from "./FunctionContextDropdownCellEdit";
import FunctionSequenceDropdownCellEdit from "./FunctionSequenceDropdownCellEdit";
import { EditorMode } from "shared/enums/EditorMode";

interface Props {
  sapFunctions: SapImportConfigFunction[];
  height: number;
  mode: EditorMode;
  onDelete: (data: any) => void;
}

const SapHostSystemParameterGrid = forwardRef((props: Props, ref) => {
  const { mode } = props;
  const gridRef = useRef<DataGrid>(null);
  const isEditMode = useRef(props.mode === EditorMode.Edit);

  const deleteRowOptions = useMemo(() => {
    return {
      visible: mode === EditorMode.Edit,
      onDelete: props.onDelete,
    };
  }, [mode]);

  useImperativeHandle(ref, () => ({
    getParameters() {
      return gridRef.current?.instance.getDataSource().items();
    },
  }));

  const ObjectTypeDropdownCellEditMemoized = useMemo(() => {
    return (props: any) => {
      return <ObjectTypeDropdownCellEdit {...props} mode={mode} />;
    };
  }, [mode]);

  const FunctionTypeDropdownCellEditMemoized = useMemo(() => {
    return (props: any) => {
      return <FunctionTypeDropdownCellEdit {...props} mode={mode} />;
    };
  }, [mode]);

  const FunctionContextDropdownCellEditMemoized = useMemo(() => {
    return (props: any) => {
      return <FunctionContextDropdownCellEdit {...props} mode={mode} />;
    };
  }, [mode]);

  const FunctionSequenceDropdownCellEditMemoized = useMemo(() => {
    return (props: any) => {
      return <FunctionSequenceDropdownCellEdit {...props} mode={mode} />;
    };
  }, [mode]);

  return (
    <PromineoEditGrid
      className={mode === EditorMode.View ? "view-only" : ""}
      dataSource={props.sapFunctions}
      displayDeleteRowOption={deleteRowOptions}
      ref={gridRef}
      keyExpr={SapHostParameterGridKey}
      allowColumnResizing={true}
      height={props.height}
    >
      <Scrolling mode={"standard"} />
      <Paging enabled={false} />
      {props.mode === EditorMode.Edit ? (
        <Editing mode={"cell"} allowUpdating={true} />
      ) : null}
      <Column
        dataField={"index"}
        showEditorAlways={props.mode === EditorMode.Edit}
        alignment={"left"}
        width={100}
        sortOrder={"asc"}
        allowSorting={false}
      />
      <Column
        dataField={"objectType"}
        alignment={"left"}
        cellComponent={ObjectTypeDropdownCellEditMemoized}
        allowEditing={false}
        allowSorting={false}
      />
      <Column
        dataField={"functionType"}
        alignment={"left"}
        cellComponent={FunctionTypeDropdownCellEditMemoized}
        allowEditing={false}
        allowSorting={false}
      />
      <Column
        dataField={"context"}
        alignment={"left"}
        cellComponent={FunctionContextDropdownCellEditMemoized}
        allowEditing={false}
        allowSorting={false}
      />
      <Column
        dataField={"sequenceType"}
        alignment={"left"}
        cellComponent={FunctionSequenceDropdownCellEditMemoized}
        allowEditing={false}
        allowSorting={false}
      />
      <Column
        dataField={"conditionFormula"}
        showEditorAlways={props.mode === EditorMode.Edit}
        alignment={"left"}
        allowSorting={false}
      />
      <Column
        dataField={"parameter"}
        showEditorAlways={props.mode === EditorMode.Edit}
        alignment={"left"}
        allowSorting={false}
      />
      <Column
        caption={"Inactive"}
        dataField={"setInactive"}
        showEditorAlways={props.mode === EditorMode.Edit}
        alignment={"center"}
        width={70}
        allowSorting={false}
        trueText={"Yes"}
        falseText={"-"}
      />
    </PromineoEditGrid>
  );
});

export default SapHostSystemParameterGrid;
