import CrossIcon from "components/icons/CrossIcon";

interface Props {
  text: string;
  allowDelete?: boolean;
  onDelete?: () => void;
}

export default function Label(props: Props) {
  return (
    <div className="bg-lightBlue rounded h-6">
      <div className="flex space-x-6.5px px-1.5 h-full text-10px leading-15px font-medium font-poppins text-textGray justify-center items-center whitespace-nowrap">
        <div>{props.text}</div>
        <div className="cursor-pointer" onClick={props.onDelete}>
          {props.allowDelete ? <CrossIcon size={10} /> : ""}
        </div>
      </div>
    </div>
  );
}
