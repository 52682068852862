import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getActiveExchangeAgreementsAsync,
  getNewExchangeAgreementsAsync,
  getExchangeAgreementDetailsAsync,
  getActiveSimplifiedExchangeAgreementsAsync,
  createExchangeAgreementForOwnerAsync,
  updateExchangeAgreementForPartnerAsync,
  signExchangeAgreementByPartnerAsync,
  signExchangeAgreementByOwnerAsync,
  pauseExchangeAgreementAsync,
  resumeExchangeAgreementAsync,
  deleteInactiveExchangeAgreementAsync,
  generateExchangeAgreementEventAsync,
  getConfigChangesPreviewAsync,
  republishExchangeAgreementAsync,
  updateExchangeAgreementAsync,
  getArchiveExchangeAgreementsAsync,
} from "apis/ExchangeAgreementApi";
import ExchangeAgreementUpdateRequest from "interfaces/request/ExchangeAgreementUpdateRequest";
import OwnerExchangeAgreementWriteRequest from "interfaces/request/OwnerExchangeAgreementWriteRequest";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";

export const loadActiveExchangeAgreementsAsync = createAsyncThunk(
  "ExchangeAgreement/loadActiveExchangeAgreementsAsync",
  async () => {
    return await getActiveExchangeAgreementsAsync();
  }
);

export const loadNewExchangeAgreementsAsync = createAsyncThunk(
  "ExchangeAgreement/loadNewExchangeAgreementsAsync",
  async () => {
    return await getNewExchangeAgreementsAsync();
  }
);

export const loadArchiveExchangeAgreementsAsync = createAsyncThunk(
  "ExchangeAgreement/loadArchiveExchangeAgreementsAsync",
  async () => {
    return await getArchiveExchangeAgreementsAsync();
  }
);

export const loadActiveSimplifiedExchangeAgreementsAsync = createAsyncThunk(
  "ExchangeAgreement/loadActiveSimplifiedExchangeAgreementsAsync",
  async () => {
    return await getActiveSimplifiedExchangeAgreementsAsync();
  }
);

export const loadExchangeAgreementDetailsAsync = createAsyncThunk(
  "ExchangeAgreement/loadExchangeAgreementDetailsAsync",
  async (exchangeAgreementId: number) => {
    return await getExchangeAgreementDetailsAsync(exchangeAgreementId);
  }
);

export const createNewExchangeAgreementForOwnerAsync = createAsyncThunk(
  "ExchangeAgreement/createExchangeAgreementForOwnerAsync",
  async (exchangeAgreementRequest: OwnerExchangeAgreementWriteRequest) => {
    return await createExchangeAgreementForOwnerAsync(exchangeAgreementRequest);
  }
);

export const updateExistingExchangeAgreementForPartnerAsync = createAsyncThunk<
  ExchangeAgreementDetailedResponse,
  { exchangeAgreementId: number; updateRequest: ExchangeAgreementUpdateRequest }
>("ExchangeAgreement/updateExchangeAgreementForPartnerAsync", async (request) => {
  return await updateExchangeAgreementForPartnerAsync(
    request.exchangeAgreementId,
    request.updateRequest
  );
});

export const updateExistingExchangeAgreementAsync = createAsyncThunk<
  ExchangeAgreementDetailedResponse,
  { exchangeAgreementId: number; updateRequest: ExchangeAgreementUpdateRequest }
>("ExchangeAgreement/updateExistingExchangeAgreement", async (request) => {
  return await updateExchangeAgreementAsync(
    request.exchangeAgreementId,
    request.updateRequest
  );
});

export const signExchangeAgreementOnBehalfOfPartnerAsync = createAsyncThunk(
  "ExchangeAgreement/signExchangeAgreementByPartnerAsync",
  async (exchangeAgreementId: number) => {
    return await signExchangeAgreementByPartnerAsync(exchangeAgreementId);
  }
);

export const signExchangeAgreementOnBehalfOfOwnerAsync = createAsyncThunk(
  "ExchangeAgreement/signExchangeAgreementByOwnerAsync",
  async (exchangeAgreementId: number) => {
    return await signExchangeAgreementByOwnerAsync(exchangeAgreementId);
  }
);

export const pauseActiveExchangeAgreementAsync = createAsyncThunk(
  "ExchangeAgreement/pauseExchangeAgreementAsync",
  async (exchangeAgreementId: number) => {
    return await pauseExchangeAgreementAsync(exchangeAgreementId);
  }
);

export const resumeActiveExchangeAgreementAsync = createAsyncThunk(
  "ExchangeAgreement/resumeExchangeAgreementAsync",
  async (exchangeAgreementId: number) => {
    return await resumeExchangeAgreementAsync(exchangeAgreementId);
  }
);

export const removeInactiveExchangeAgreementAsync = createAsyncThunk(
  "ExchangeAgreement/deleteInactiveExchangeAgreementAsync",
  async (exchangeAgreementId: number) => {
    return await deleteInactiveExchangeAgreementAsync(exchangeAgreementId);
  }
);

export const generateExchangeAgreementDataTransferEventAsync = createAsyncThunk(
  "ExchangeAgreement/generateExchangeAgreementEventAsync",
  async (exchangeAgreementId: number) => {
    return await generateExchangeAgreementEventAsync(exchangeAgreementId);
  }
);

export const loadConfigChangesPreviewAsync = createAsyncThunk(
  "ExchangeAgreement/loadConfigChangesPreview",
  async (exchangeAgreementId: number) => {
    return await getConfigChangesPreviewAsync(exchangeAgreementId);
  }
);

export const republishExchangeAgreementForConfigChangeAsync = createAsyncThunk(
  "ExchangeAgreement/republishExchangeAgreementForConfigChange",
  async (exchangeAgreementId: number) => {
    return await republishExchangeAgreementAsync(exchangeAgreementId);
  }
);
