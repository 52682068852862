import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import { loadActiveExchangeAgreementsAsync } from "store/actions/ExchangeAgreementActions";
import { AppDispatch } from "store/store";
import ActiveIEAsGrid from "./ActiveIEAsGrid";
import ActiveIEAGridSummary from "./ActiveIEAGridSummary";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import { ActiveIEASummaryFilterOption } from "shared/enums/feature/ActiveIEASummaryFilterOption";
import { useAppSelector } from "store/hooks";
import NewIEAWizard from "./new-iea/NewIEAWizard";

export default function ActiveIEAs() {
  const headerTitleDivId: string = "active-ieas-header-title";
  const excludedContainerIds: string[] = [headerTitleDivId];
  const activeExchangeAgreementList = useAppSelector(
    (store) => store.exchangeAgreementData.activeExchangeAgreements
  );

  const dispatch = useDispatch<AppDispatch>();
  const [filteredExchangeAgreementList, setFilteredExchangeAgreementList] =
    useState<ActiveExchangeAgreementListingResponse[]>([]);
  const [failedExchangeAgreementList, setFailedExchangeAgreementList] =
    useState<ActiveExchangeAgreementListingResponse[]>([]);
  const [overdueExchangeAgreementList, setOverdueExchangeAgreementList] =
    useState<ActiveExchangeAgreementListingResponse[]>([]);
  const [pausedExchangeAgreementList, setPausedExchangeAgreementList] =
    useState<ActiveExchangeAgreementListingResponse[]>([]);
  const [successfulExchangeAgreementList, setSuccessfulExchangeAgreementList] =
    useState<ActiveExchangeAgreementListingResponse[]>([]);
  const [
    updateAvailableExchangeAgreementList,
    setUpdateAvailableExchangeAgreementList,
  ] = useState<ActiveExchangeAgreementListingResponse[]>([]);
  const [selectedFilter, setSelectedFilter] =
    useState<ActiveIEASummaryFilterOption>(ActiveIEASummaryFilterOption.Total);
  const [displayNewIeaWizard, setDisplayNewIeaWizard] = useState(false);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadActiveExchangeAgreementsAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  const openNewIeaWizard = () => {
    setDisplayNewIeaWizard(true);
  };

  const hideNewIeaWizard = () => {
    setDisplayNewIeaWizard(false);
  };

  useEffect(() => {
    let failedExchangeAgreements: ActiveExchangeAgreementListingResponse[] = [];
    let overdueExchangeAgreements: ActiveExchangeAgreementListingResponse[] =
      [];
    let pausedExchangeAgreements: ActiveExchangeAgreementListingResponse[] = [];
    let successfulAgreements: ActiveExchangeAgreementListingResponse[] = [];
    let updateAvailableExchangeAgreements: ActiveExchangeAgreementListingResponse[] =
      [];

    for (
      let activeIEAIndex = 0;
      activeIEAIndex < activeExchangeAgreementList.length;
      activeIEAIndex++
    ) {
      let activeIEA = activeExchangeAgreementList[activeIEAIndex];
      if (activeIEA.updateRequired) {
        updateAvailableExchangeAgreements.push(activeIEA);
      }

      if (activeIEA.isPaused) {
        pausedExchangeAgreements.push(activeIEA);
      } else {
        // If LastUploadStatus or LastDownloadStatus is null, then we are considering it as Failed.
        if (activeIEA.lastUploadStatus) {
          if (activeIEA.lastUploadStatus === DataTransferStatus.Fail) {
            failedExchangeAgreements.push(activeIEA);
          } else if (
            activeIEA.lastUploadStatus === DataTransferStatus.Overdue
          ) {
            overdueExchangeAgreements.push(activeIEA);
          } else if (
            activeIEA.lastUploadStatus === DataTransferStatus.Success
          ) {
            if (activeIEA.lastDownloadStatus) {
              if (activeIEA.lastDownloadStatus === DataTransferStatus.Fail) {
                failedExchangeAgreements.push(activeIEA);
              } else if (
                activeIEA.lastDownloadStatus === DataTransferStatus.Overdue
              ) {
                overdueExchangeAgreements.push(activeIEA);
              } else if (
                activeIEA.lastDownloadStatus === DataTransferStatus.Success
              ) {
                successfulAgreements.push(activeIEA);
              }
            } else {
              failedExchangeAgreements.push(activeIEA);
            }
          }
        } else {
          failedExchangeAgreements.push(activeIEA);
        }
      }
    }

    setFailedExchangeAgreementList(failedExchangeAgreements);
    setOverdueExchangeAgreementList(overdueExchangeAgreements);
    setPausedExchangeAgreementList(pausedExchangeAgreements);
    setUpdateAvailableExchangeAgreementList(updateAvailableExchangeAgreements);
    setSuccessfulExchangeAgreementList(successfulAgreements);

    // By default we want to display all active IEA's. For this reason, we are setting
    // the value for "FilteredExchangeAgreementList" as "activeExchangeAgreementList".
    setFilteredExchangeAgreementList(activeExchangeAgreementList);
  }, [activeExchangeAgreementList]);

  const handleFailedIEASummaryButtonClick = () => {
    setSelectedFilter(ActiveIEASummaryFilterOption.Failed);
    setFilteredExchangeAgreementList(failedExchangeAgreementList);
  };

  const handleOverdueIEASummaryButtonClick = () => {
    setSelectedFilter(ActiveIEASummaryFilterOption.Overdue);
    setFilteredExchangeAgreementList(overdueExchangeAgreementList);
  };

  const handlePausedIEASummaryButtonClick = () => {
    setSelectedFilter(ActiveIEASummaryFilterOption.Paused);
    setFilteredExchangeAgreementList(pausedExchangeAgreementList);
  };

  const handleUpdateAvailableIEASumeryButonClick = () => {
    setSelectedFilter(ActiveIEASummaryFilterOption.UpdateAvailable);
    setFilteredExchangeAgreementList(updateAvailableExchangeAgreementList);
  };

  const handleTotalIEASummaryButtonClick = () => {
    setSelectedFilter(ActiveIEASummaryFilterOption.Total);
    setFilteredExchangeAgreementList(activeExchangeAgreementList);
  };

  const handleSuccessfulIEASummaryButtonClick = () => {
    setSelectedFilter(ActiveIEASummaryFilterOption.Success);
    setFilteredExchangeAgreementList(successfulExchangeAgreementList);
  };

  const ieasGridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 70,
  });

  return (
    <>
      <div className="mb-4" id={headerTitleDivId}>
        <PageHeaderTitle title="Active IEAs" />
      </div>
      <ActiveIEAsGrid
        activeIEAs={filteredExchangeAgreementList}
        height={ieasGridHeight}
        onCreateNewClick={openNewIeaWizard}
        gridWidget={
          <ActiveIEAGridSummary
            updateAvailablIEAs={updateAvailableExchangeAgreementList.length}
            failedIEAs={failedExchangeAgreementList.length}
            overdueIEAs={overdueExchangeAgreementList.length}
            pausedIEAs={pausedExchangeAgreementList.length}
            totalIEAs={activeExchangeAgreementList.length}
            successfulEAs={successfulExchangeAgreementList.length}
            selectedFilter={selectedFilter}
            onFailedIEASummaryButtonClick={handleFailedIEASummaryButtonClick}
            onOverdueIEASummaryButtonClick={handleOverdueIEASummaryButtonClick}
            onPausedIEASummaryButtonClick={handlePausedIEASummaryButtonClick}
            onUpdateAvailableIEASummeryButtonClick={
              handleUpdateAvailableIEASumeryButonClick
            }
            onTotalIEASummaryButtonClick={handleTotalIEASummaryButtonClick}
            onSuccessIeaSummaryButtonClick={
              handleSuccessfulIEASummaryButtonClick
            }
          />
        }
      />
      {displayNewIeaWizard && <NewIEAWizard onClose={hideNewIeaWizard} />}
    </>
  );
}
