import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { deepCopyObject, getClipboardData } from "shared/utilities/CommonUtility";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { getEmptyConfigDetail } from "shared/utilities/ConfigUtility";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";

interface Props {
  destinationConfig: ConfigDetailResponse | null;
  selectedConnectorDetail: ConnectorDetailsResponse | null;
  showErrorModal: () => void;
}

interface ValidationErrors {
  errors: string[];
  count: number;
}

export default function useImportConfigFromClipboard(props: Props) {
  const { destinationConfig, selectedConnectorDetail, showErrorModal } = props;

  const location = useLocation();
  const [configFromClipboard, setConfigFromClipboard] = useState<ConfigDetailResponse>(getEmptyConfigDetail());
  const [importSuccessful, setImportSuccessful] = useState<boolean>(false);
  const [shouldGetConfigFromClipboard, setShouldGetConfigFromClipboard] = useState<boolean>(location.state?.getConfigFromClipboard ?? false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({ errors: [], count: 0 });

  useEffect(() => {
    if (!shouldGetConfigFromClipboard || !destinationConfig || !selectedConnectorDetail) {
      return;
    }

    const getConfigFromClipboard = async () => {
      const configInClipboard = await getClipboardData();

      const globalWarnings: string[] = [];
      let warningCount = 0;

      const addWarningLine = (warning: string) => { globalWarnings.push(warning); }
      const addWarningLinesWithCount = (warnings: string[]) => warnings.forEach(warning => addWarningLine(warning));
      const addWarningSeparator = () => addWarningLine("------------------------------------------");

      try {
        const configToImport = JSON.parse(configInClipboard) as ConfigDetailResponse;
        const existingConfig = deepCopyObject(destinationConfig) as ConfigDetailResponse;

        // if the connector and the config direction does not match, we do not import anything
        if (!(configToImport.connectorHostSystem === existingConfig.connectorHostSystem && configToImport.direction === existingConfig.direction)) {
          addWarningLine(`Could not import config - The connector host system '${HostSystem[configToImport.connectorHostSystem ?? 0]}' and direction '${configToImport.directionText}' does not match with the destination config.`);
          return;
        }

        const existingConfigCodeSet = existingConfig.schedules && existingConfig.schedules.length !== 0 ? existingConfig.schedules[0].userFieldSetId : null;

        const setDirectMapping = (fieldMapping: FieldMappingResponse, fieldMappingToImport: FieldMappingResponse): string[] => {
          const localWarnings: string[] = [];

          fieldMapping.hasDirectMapping = fieldMappingToImport.hasDirectMapping;

          if (fieldMapping.planningObjectType !== fieldMappingToImport.planningObjectType) {
            localWarnings.push(`Could not map host field '${fieldMappingToImport.mappedConnectorHostFieldName}' - Planning object type does not match for term '${fieldMappingToImport.name}'`);
            warningCount++;
            return localWarnings;
          }

          var hostField = selectedConnectorDetail?.hostFields?.find((hf) =>
            hf.planningObjectType === fieldMappingToImport.planningObjectType &&
            hf.name === fieldMappingToImport.mappedConnectorHostFieldName &&
            (!hf.userFieldSetId || (existingConfigCodeSet && existingConfigCodeSet === hf.userFieldSetId)))

          if (!hostField) {
            localWarnings.push(`Could not map host field '${fieldMappingToImport.mappedConnectorHostFieldName}' - Does not exist in the destination connector`);
            warningCount++;
            return localWarnings;
          }

          fieldMapping.mappedConnectorHostFieldId = hostField.id;
          fieldMapping.mappedConnectorHostFieldName = hostField.name;

          return localWarnings;
        }

        const setValueMaps = (fieldMapping: FieldMappingResponse, fieldMappingToImport: FieldMappingResponse): string[] => {
          const localWarnings: string[] = [];

          fieldMapping.formula = fieldMappingToImport.formula;

          if (fieldMapping.allowContentControl !== fieldMappingToImport.allowContentControl) {
            localWarnings.push(`Could not set any value map - Allow content control value does not match for ${fieldMappingToImport.name}.`);
            warningCount++;
            return localWarnings;
          }

          // if content control is not allowed, we keep the existing maps and append the imported maps
          if (!fieldMapping.allowContentControl) {
            fieldMapping.valueMaps = [...fieldMapping.valueMaps, ...fieldMappingToImport.valueMaps];
            return localWarnings;
          }

          let valueMapNotAbleToSetCount = 0;

          // if content control is allowed, then...
          for (let index = 0; index < fieldMappingToImport.valueMaps.length; index++) {
            const vmImport = fieldMappingToImport.valueMaps[index];

            // if the (template_value, host_value) pair exists, do nothing
            const existingMap = fieldMapping.valueMaps.find(vm => vm.fieldValueText === vmImport.fieldValueText && vm.value === vmImport.value);

            if (existingMap) {
              continue;
            }

            // if the map does not exist, but the value is available, append the map
            const existingValue = fieldMapping.values.find(v => v.code === vmImport.fieldValueText);
            if (existingValue) {
              // we keep the field value id and description the same as the existing one
              fieldMapping.valueMaps.push({ 
                ...vmImport, 
                fieldValueId: existingValue.id, 
                fieldValueDescription: existingValue.description });
            }
            else {
              valueMapNotAbleToSetCount++;
              localWarnings.push(`Could not set value map - '${vmImport.fieldValueText}' is not defined in the destination.`);
              warningCount++;
            }
          }

          if (valueMapNotAbleToSetCount > 0) {
            localWarnings.push(`Could not set ${valueMapNotAbleToSetCount} value maps in total.`);
          }

          return localWarnings;
        }

        const setHostSystemParameters = (config: ConfigDetailResponse, importConfig: ConfigDetailResponse) => {
          config.hostSystemParameters = importConfig.hostSystemParameters;
        }

        for (let index = 0; index < configToImport.mappings.length; index++) {
          const fieldMappingToImport = configToImport.mappings[index];

          let existingFieldIndex = existingConfig.mappings.findIndex(m =>
            m.name === fieldMappingToImport.name &&
            m.planningObjectType === fieldMappingToImport.planningObjectType &&
            m.dataType === fieldMappingToImport.dataType &&
            m.isConfigTemplateFieldMapping === fieldMappingToImport.isConfigTemplateFieldMapping &&
            m.isCoreFieldMapping === fieldMappingToImport.isCoreFieldMapping);

          if (existingFieldIndex < 0) {
            if (fieldMappingToImport.isConfigTemplateFieldMapping) {
              addWarningLine(`Could not map ILAP term '${fieldMappingToImport.name}' - No template field found.`);
              warningCount++;
            }
            else {
              addWarningLine(`Could not map ILAP term '${fieldMappingToImport.name}' - No core field found.`);
              warningCount++;
            }

            addWarningSeparator();

            continue;
          }

          const directMappingWarnings = setDirectMapping(existingConfig.mappings[existingFieldIndex], fieldMappingToImport);
          const valueMapWarnings = setValueMaps(existingConfig.mappings[existingFieldIndex], fieldMappingToImport);

          // add warnings to 
          const totalWarnings = [...directMappingWarnings, ...valueMapWarnings];

          if (totalWarnings.length > 0) {
            addWarningLine(`For ILAP term - '${fieldMappingToImport.name}'`);
            addWarningLinesWithCount(totalWarnings);
            addWarningSeparator();
          }
        }

        setHostSystemParameters(existingConfig, configToImport);

        setConfigFromClipboard(existingConfig);
        setImportSuccessful(true);
      }
      catch (ex: any) {
        addWarningLine(ex.message);
      }
      finally {
        if (globalWarnings.length > 0) {
          setValidationErrors({ errors: globalWarnings, count: warningCount });
          showErrorModal();
        }
        setShouldGetConfigFromClipboard(false);
      }
    }

    getConfigFromClipboard();

  }, [shouldGetConfigFromClipboard, destinationConfig, selectedConnectorDetail, showErrorModal]);

  return {
    configFromClipboard,
    importSuccessful,
    validationErrors,
    setShouldGetConfigFromClipboard,
  };
}
