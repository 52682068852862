import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import ConnectorResponse from "interfaces/response/connector/ConnectorResponse";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { useNavigate } from "react-router-dom";
import { CONNECTORS } from "shared/constants/RoutePathConstants";
import LastHealthCheckComponent from "./LastHealthCheckComponent";
import ConnectorCreateOrCopyDialog from "./ConnectorCreateOrCopyDialog";
import SafranApiConnectorConfigurationResponse from "interfaces/response/connector/SafranApiConnectorConfigurationResponse";
import { SafranApiConnectorAuthenticationMode } from "shared/enums/feature/safran-host-system/SafranApiConnectorAuthenticationMode";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import { ConnectorHealthCheckStatus } from "shared/enums/feature/ConnectorHealthCheckStatus";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadConnectorDetailsAsync } from "store/actions/ConnectorActions";
import { unwrapResult } from "@reduxjs/toolkit";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import "components/common/grid/styles/PromineoUIGrid.css";
import LabelHolder from "components/common/LabelHolder";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { resetSelectedConnector } from "store/slices/ConnectorSlice";

interface Props {
  connectors: ConnectorResponse[];
  height: number;
}

const initSafranApiConnectorConfiguration: SafranApiConnectorConfigurationResponse =
  {
    baseUrl: "",
    useOwnerVerification: false,
    username: "",
    password: "",
    owner: "",
    authenticationMode: -1 as SafranApiConnectorAuthenticationMode,
    authenticationModeDisplayText: "",
    adTenantId: "",
    clientApplicationId: "",
    redirectUri: "",
  };

const initConnector: ConnectorResponse = {
  id: 0,
  name: "",
  hostSystem: -1 as HostSystem,
  hostSystemName: "",
  definition: "",
  tenantId: 0,
  executionComponent: -1 as ExecutionComponent,
  ieaCount: 0,
  executionComponentText: "",
  healthCheckStatus: ConnectorHealthCheckStatus.NeverChecked,
  healthCheckStatusText: "Never Checked",
  urlOrPath: "",
  configuration: initSafranApiConnectorConfiguration,
  isHealthCheckRequestAllowed: false,
  labels: [],
  canUpload: false,
  canDownload: false
};

export default function ConnectorsGrid(props: Props) {
  let navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isCreateOrCopyDialogVisible, setIsCreateOrCopyDialogVisible] =
    useState(false);
  const [selectedConnector, setSelectedConnector] =
    useState<ConnectorResponse>(initConnector);
  const [modalMode, setModalMode] = useState<PromineoModalMode>(
    PromineoModalMode.Create
  );

  const handleNewConnectorClick = () => {
    setSelectedConnector(initConnector);
    setModalMode(PromineoModalMode.Create);
    setIsCreateOrCopyDialogVisible(true);
  };

  const handleCancleClick = () => {
    setIsCreateOrCopyDialogVisible(false);
  };

  const handleOpenConnectorClick = (connector: ConnectorResponse) => {
    navigate(`${CONNECTORS}/${connector.id}`);
  };

  const handleModifyConnectorClick = (connector: ConnectorResponse) => {
    navigate(`${CONNECTORS}/${connector.id}/edit`);
  };

  const handleCopyConnectorClick = (connector: ConnectorResponse) => {
    displayLoadingPanel();
    dispatch(loadConnectorDetailsAsync(connector.id))
      .then(unwrapResult)
      .then((connectorResponse: ConnectorDetailsResponse) => {
        const connectorToCopy = { ...connectorResponse };
        connectorToCopy.name = "";

        setSelectedConnector(connectorToCopy);
        // Both for create and copy we are setting ModalMode as Create.
        setModalMode(PromineoModalMode.Create);
        setIsCreateOrCopyDialogVisible(true);
      })
      .finally(()=>{
        hideLoadingPanel();
        dispatch(resetSelectedConnector());
      });
  };

  const LastHealthCheckCell = ({ data }: { data: ConnectorResponse }) => {
    return (
      <LastHealthCheckComponent
        healthCheckStatus={data.healthCheckStatus}
        healthCheckedAt={data.healthCheckedAt}
        isHealthCheckRequestAllowed={data.isHealthCheckRequestAllowed}
      />
    );
  };

  const RenderLabelsCell = ({ data }: { data: ConnectorResponse }) => {
    const labels = data.labels;
    return (
      <div>
        <LabelHolder
          labels={labels}
          labelType={SupportedLabelEntity.Connector}
        />
      </div>
    );
  };

  const handleRowClick = useCallback((data: any) => {
    handleOpenConnectorClick(data.data);
  }, []);

  return (
    <>
      <PromineoViewGrid
        height={props.height}
        dataSource={props.connectors}
        className="connectors-grid promineo-ui-grid"
        onRowClick={handleRowClick}
        toolbarConfig={{
          dislplaySearchPanel: true,
          displayAddNewButton: true,
          addNewButtonText: "New Connector",
          onClickAddNew: handleNewConnectorClick,
        }}
        rowOperationConfig={{
          visible: true,
          displayDefault: true,
          defaultOptionConfig: {
            onOpen: (data: any) => handleOpenConnectorClick(data.data),
            onCopy: (data: any) => handleCopyConnectorClick(data.data),
            onModify: (data: any) => handleModifyConnectorClick(data.data),
          },
        }}
      >
        <Scrolling mode={"virtual"} />
        <Column caption={"Id"} dataField="id" alignment="left" width={100} />
        <Column caption={"Title"} dataField="name" alignment="left" />
        <Column
          caption={"Host System"}
          dataField="hostSystemName"
          alignment="left"
        />
        <Column
          caption={"Labels"}
          cssClass={"custom-control-padding"}
          cellRender={RenderLabelsCell}
          allowFiltering={false}
        />
        <Column
          caption={"URL/Path"}
          dataField="urlOrPath"
          alignment="left"
          width={400}
        />
        <Column
          caption={"Execute component"}
          dataField="executionComponentText"
          alignment="left"
        />
        <Column
          cssClass="add-left-border"
          caption={"# of IEAs"}
          dataField="ieaCount"
          alignment="left"
          width={100}
        />
        <Column
          caption={"Last health check"}
          alignment="left"
          cellRender={LastHealthCheckCell}
        />
      </PromineoViewGrid>
      {isCreateOrCopyDialogVisible && (
        <ConnectorCreateOrCopyDialog
          connector={selectedConnector}
          connectorConfiguration={selectedConnector.configuration}
          modalMode={modalMode}
          handleCancelClick={handleCancleClick}
        />
      )}
    </>
  );
}
