import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import PromineoViewGrid, {
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import TemplateFieldWithValueCountResponse from "interfaces/response/TemplateFieldWithValueCountResponse";
import "components/common/grid/styles/PromineoUIGrid.css";
import { TEMPLATE_FIELDS } from "shared/constants/RoutePathConstants";
import ContentControlInformationCellComponent from "features/templates/view-template/ContentControlInformationCellComponent";

interface Props {
  templateFieldData?: TemplateFieldWithValueCountResponse[] | null;
  height: number;
}

export default function ViewTemplateGrid(props: Props) {
  let navigate = useNavigate();

  const navigateToTemplateFieldViewer = (evt: {
    data: TemplateFieldWithValueCountResponse;
  }) => {
    navigate(`${TEMPLATE_FIELDS}/${evt.data.id}`);
  };

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    displayDefault: false,
    items: [
      {
        text: "Open",
        onClick: navigateToTemplateFieldViewer,
        visible: true,
      },
    ],
  });

  return (
    <div>
      <PromineoViewGrid
        dataSource={props.templateFieldData}
        onRowClick={navigateToTemplateFieldViewer}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig.current}
        height={props.height}
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"Planning object type"}
          dataField="planningObjectTypeText"
          alignment="left"
        />
        <Column caption={"ILAP Term"} dataField="name" alignment="left" />
        <Column
          caption={"Description"}
          dataField="description"
          alignment="left"
        />
        <Column
          caption={"Data type"}
          dataField="dataTypeText"
          alignment="left"
        />
        <Column
          caption={"Content control"}
          dataField="contentControlText"
          alignment="left"
          cellComponent={ContentControlInformationCellComponent}
        />
        <Column
          caption={"# of values"}
          dataField="valueCount"
          alignment="left"
        />
      </PromineoViewGrid>
    </div>
  );
}
