import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import ConfigResponse from "interfaces/response/ConfigResponse";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CONFIGS } from "shared/constants/RoutePathConstants";
import {
  copyConfigByIdAsync,
  loadConfigsAsync,
} from "store/actions/ConfigActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import "components/common/grid/styles/PromineoUIGrid.css";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import ResponseBase from "interfaces/response/ResponseBase";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import ConfigsGrid from "./ConfigsGrid";

export default function Configs() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const configs = useAppSelector((store) => store.configData.configs);

  const [selectedConfigToCopy, setSelectedConfigToCopy] = useState<{
    configId: number;
    configTitle: string;
  } | null>(null);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadConfigsAsync()).finally(hideLoadingPanel);
  }, []);

  const handleCopyConfigClick = useCallback((evt: { data: ConfigResponse }) => {
    setSelectedConfigToCopy({
      configId: evt.data.id,
      configTitle: evt.data.title,
    });
  }, []);

  const handleCopyCancelClicked = useCallback(() => {
    setSelectedConfigToCopy(null);
  }, []);

  const handleCopyConfirmClicked = useCallback(() => {
    if (selectedConfigToCopy) {
      displayLoadingPanel();
      dispatch(copyConfigByIdAsync(selectedConfigToCopy.configId))
        .unwrap()
        .then((copiedConfig: ResponseBase) => {
          navigate(`${CONFIGS}/${copiedConfig.id}/edit`);
        })
        .finally(hideLoadingPanel);

      setSelectedConfigToCopy(null);
    }
  }, [selectedConfigToCopy]);

  const headerDivId: string = "configs-header";
  const excludedContainerIds: string[] = [headerDivId];

  const configsGridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  return (
    <div>
      <div className="mb-4" id={headerDivId}>
        <PageHeaderTitle title="Configs" />
      </div>
      <div className="flex justify-center">
        <ConfigsGrid
          height={configsGridHeight}
          configs={configs}
          onCopyConfigClick={handleCopyConfigClick}
        />
      </div>
      {selectedConfigToCopy && (
        <PromineoConfirmationDialog
          onConfirm={handleCopyConfirmClicked}
          onCancel={handleCopyCancelClicked}
          content={`Are you sure you want to copy config "${selectedConfigToCopy.configTitle}"?`}
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
        />
      )}
    </div>
  );
}
