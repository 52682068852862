import MappingFormulaResponse from "interfaces/response/MappingFormulaResponse";
import { MappingFormulaType } from "shared/enums/feature/MappingFormulaType";

export const mappingFormulaList: MappingFormulaResponse[] = [
  {
    formula: `[VALUE] = "Hello"`,
    description: `Test if value is the string "Hello" (case sensitive!)`,
    type: MappingFormulaType.Boolean,
    sortOrder: 10,
  },
  {
    formula: `!([VALUE] = "Hello")`,
    description: `NOT operator (reverse boolean result). "!" means "NOT"`,
    type: MappingFormulaType.Boolean,
    sortOrder: 15,
  },
  {
    formula: `[VALUE].ToUpper() = "HELLO"`,
    description: `Test if value is the string "hello" (case insensitive)`,
    type: MappingFormulaType.Boolean,
    sortOrder: 20,
  },
  {
    formula: `([VALUE] = "Hei") & (Activity.Code = “A0010”)`,
    description: `Testing multiple fields with AND operator`,
    type: MappingFormulaType.Boolean,
    sortOrder: 40,
  },
  {
    formula: `([VALUE] = "Hei") | (Activity.Code = “A0010”)`,
    description: `Testing multiple fields with OR operator`,
    type: MappingFormulaType.Boolean,
    sortOrder: 50,
  },
  {
    formula: `Sys.Right([VALUE],2)`,
    description: `Take the rightmost 2 characters of a string value.`,
    type: MappingFormulaType.String,
    sortOrder: 100,
  },
  {
    formula: `[VALUE].ToUpper()`,
    description: `Make value upper case`,
    type: MappingFormulaType.String,
    sortOrder: 102,
  },
  {
    formula: `[VALUE].ToLower()`,
    description: `Make value lower case`,
    type: MappingFormulaType.String,
    sortOrder: 104,
  },
  {
    formula: `Sys.GetPart([VALUE], ".", "L")`,
    description: `Get part of string that is to Left of "."`,
    type: MappingFormulaType.String,
    sortOrder: 110,
  },
  {
    formula: `Sys.GetPart([VALUE], ".", "R")`,
    description: `Get part of string that is to Right of "."`,
    type: MappingFormulaType.String,
    sortOrder: 120,
  },
  {
    formula: `[VALUE].Substring(1, 3)`,
    description: `Get the 3 characters in string starting from the second character`,
    type: MappingFormulaType.String,
    sortOrder: 140,
  },
  {
    formula: `[VALUE].Substring(1)`,
    description: `Get string starting from the second character (index=1)`,
    type: MappingFormulaType.String,
    sortOrder: 150,
  },
  {
    formula: `Sys.Truncate([VALUE], 10)`,
    description: `Truncate the string to max 10 characters`,
    type: MappingFormulaType.String,
    sortOrder: 155,
  },
  {
    formula: `Sys.GetPartIndex([VALUE], ".", 1)`,
    description: `Get the second element in the "."-separated string (E.g: Element "1" in "ab.c.def" is "c")`,
    type: MappingFormulaType.String,
    sortOrder: 160,
  },
  {
    formula: `[VALUE] + "-" + Activity.Code + "-" + Activity.Description`,
    description: `Concatenate multiple string fields`,
    type: MappingFormulaType.String,
    sortOrder: 170,
  },
  {
    formula: `DateTime.Today`,
    description: `Get today's date (with 00:00 as hours and minutes)`,
    type: MappingFormulaType.DateTime,
    sortOrder: 200,
  },
  {
    formula: `DateTime.Now`,
    description: `Get today's date with hours, minutes and seconds`,
    type: MappingFormulaType.DateTime,
    sortOrder: 204,
  },
  {
    formula: `[VALUE].AddDays(7)`,
    description: `Get date that is 7 days after the value's date.`,
    type: MappingFormulaType.DateTime,
    sortOrder: 206,
  },
  {
    formula: `[2022-09-01]`,
    description: `Specify a specific date (in this example September 1st, 2022)`,
    type: MappingFormulaType.DateTime,
    sortOrder: 210,
  },
  {
    formula: `DateTime.Parse("2022-09-01")`,
    description: `Convert string (yyyy-MM-dd) to DateTime type`,
    type: MappingFormulaType.DateTime,
    sortOrder: 212,
  },
  {
    formula: `Calendar.GetFirstCurrentDate("w", DateTime.Today)`,
    description: `Get first day in current week (monday). "m" = Month, "q"=Quarter, "y"=Year`,
    type: MappingFormulaType.DateTime,
    sortOrder: 220,
  },
  {
    formula: `General.Iif([VALUE]>5,"X","Y")`,
    description: `Return "X" if value is greater than 5, otherwise "Y"`,
    type: MappingFormulaType.General,
    sortOrder: 300,
  },
  {
    formula: `General.IsNull([VALUE]."")`,
    description: `Return emtpy string ("") if the value is Null (blank)`,
    type: MappingFormulaType.General,
    sortOrder: 310,
  },
  {
    formula: `General.GetEnvironmentVariable("USERNAME")`,
    description: `Get the login name of a user`,
    type: MappingFormulaType.General,
    sortOrder: 320,
  },
  {
    formula: `vm#MyILAPTerm.Map([VALUE], "X")`,
    description: `Use a value map to look up a return value, and return "X" if key not found.`,
    type: MappingFormulaType.ValueMap,
    sortOrder: 400,
  },
  {
    formula: `Array.IndexOf( { "Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun" }, [VALUE]) > (-1)`,
    description: `Test if value is in a list of defined values. Note: The "IndexOf" method returns -1 if value is not found in list`,
    type: MappingFormulaType.Array,
    sortOrder: 500,
  },
  {
    formula: `General.Sum(General.EvaluateForeach(Activity.Resources.ToArray(), "General.ToDouble(Value.GetCustomFieldValue(\"ResourceHoursOfWork\"))"))`,
    description: `Aggregate all resource hours for an activity`,
    type: MappingFormulaType.Aggregation,
    sortOrder: 600,
  },
  {
    formula: `Math.Max([VALUE],10)`,
    description: `Take the max of 10 and the [VALUE]`,
    type: MappingFormulaType.Math,
    sortOrder: 700,
  },
  {
    formula: `Math.Round([VALUE], 0)`,
    description: `Round a number to 0 decimals`,
    type: MappingFormulaType.Math,
    sortOrder: 710,
  },
  {
    formula: `Math.Abs([VALUE])`,
    description: `Take the absolute value of a number`,
    type: MappingFormulaType.Math,
    sortOrder: 720,
  },
];
