import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";

export const CheckIfPartnerResponsibleFieldsAreFilled = (selectedExchangeAgreement: ExchangeAgreementDetailedResponse) => {
  return (
    selectedExchangeAgreement.partnerRepresentatives?.length > 0 &&
    selectedExchangeAgreement.partnerConnector &&
    selectedExchangeAgreement.partnerSchedule && 
    selectedExchangeAgreement.partnerConfigSettings
  );
}

export const IsSignedByPartner = (selectedExchangeAgreement: ExchangeAgreementDetailedResponse) => {
  return (selectedExchangeAgreement.partnerSigner && selectedExchangeAgreement.partnerSignatureTime);
}

export const IsSignedByOwner = (selectedExchangeAgreement: ExchangeAgreementDetailedResponse) => {
  return (selectedExchangeAgreement.ownerSigner && selectedExchangeAgreement.ownerSignatureTime);
}

export const getHeaderInformationText = (selectedExchangeAgreement: ExchangeAgreementDetailedResponse, isCurrentUserOwner: boolean, isCurrentUserPartner: boolean) => {
  let headerText = "";

  if (selectedExchangeAgreement?.actionRequiredIfInactive) {
    if (isCurrentUserOwner && !isCurrentUserPartner) {
      if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.Complete) {
        headerText = "Fill out owner configuration";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.WaitingForPartner) {
        headerText = "Wait for partner to fill out partner configuration";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.WaitingForPartnerSign) {
        headerText = "Wait for partner to sign IEA";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.SignOff) {
        headerText = "Sign IEA to complete IEA";
      }
      else {
        headerText = `IEA completed and will start on ${selectedExchangeAgreement.startDate}.`
      }
    }
    else if (isCurrentUserPartner && !isCurrentUserOwner) {
      if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.Complete) {
        headerText = "Fill out partner configuration";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.WaitingForOwner) {
        headerText = "Wait for owner to fill out owner configuration";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.WaitingForOwnerSign) {
        headerText = "Wait for owner to sign IEA";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.SignOff) {
        headerText = "Sign IEA to continue";
      }
    }
    else if (isCurrentUserOwner && isCurrentUserPartner) {
      if (CheckIfPartnerResponsibleFieldsAreFilled(selectedExchangeAgreement)) {
        if (IsSignedByPartner(selectedExchangeAgreement)) {
          if (IsSignedByOwner(selectedExchangeAgreement)) {
            headerText = `IEA completed and will start on ${selectedExchangeAgreement.startDate}.`
          }
          else {
            headerText = "Wait for owner to sign IEA";
          }
        }
        else {
          headerText = "Wait for partner to sign IEA";
        }
      }
      else {
        headerText = "Fill out partner configuration";
      }
    }
  }

  return headerText;
}