import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMappingFormulasAsync } from "apis/MappingFormulaApi";
import { RootState } from "store/store";

export const loadMappingFormulasAsync = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("mappingFormulas", async (request, thunkApi) => {
  const state = thunkApi.getState();
  const existingMappingFormulaValues = state.mappingFormulaData.mappingFormulas;

  if (existingMappingFormulaValues.length === 0) {
    return await getMappingFormulasAsync();
  } else {
    return existingMappingFormulaValues;
  }
});
