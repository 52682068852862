import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { useCallback, useState, useRef } from "react";
import DirectMappingToggleButton from "./DirectMappingToggleButton";
import HostFieldEditor from "./HostFieldEditor";
import "components/common/grid/styles/PromineoUIGrid.css";
import ContentControlCellComponent from "components/common/ContentControlCellComponent";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";

export interface DirectMappingEditorProps {
  height: number;
  isEdit: boolean;
  fieldMappings: FieldMappingResponse[];
  onHostFieldChange?: (
    mapping: FieldMappingResponse,
    hostField: HostFieldResponse
  ) => void;
  onDirectMappingToggle?: (
    mapping: FieldMappingResponse,
    hasDirectMapping: boolean
  ) => void;
  selectedCodeSet?: number | null;
  displaySearch?: boolean;
  isPreviewChange?: boolean;
}

export default function DirectMappingEditor(props: DirectMappingEditorProps) {
  const [
    isDirectMappingToggleConfirmVisible,
    setIsDirectMappingToggleConfirmVisible,
  ] = useState<boolean>(false);

  const [directMappingRowData, setDirectMappingRowData] =
    useState<FieldMappingResponse | null>(null);

  const HostFieldComponent = useCallback(
    (data: { data: FieldMappingResponse }) => {
      return props.selectedCodeSet || props.selectedCodeSet === null ? (
        <HostFieldEditor
          fieldMapping={data.data}
          onSelectionChange={(selectedHostField: HostFieldResponse) => {
            props.onHostFieldChange?.(data.data, selectedHostField);
          }}
          selectedCodeSet={props.selectedCodeSet}
        />
      ) : null;
    },
    [props.selectedCodeSet]
  );

  const onDirectMappingToggleOnConfirm = () => {
    // This is available only when turning on direct mapping for a row
    var isChecked = true;
    if (directMappingRowData) {
      props.onDirectMappingToggle?.(directMappingRowData, isChecked);
    }
    setIsDirectMappingToggleConfirmVisible(false);
  };

  const DirectMappingToggleButtonComponent = useCallback(
    (data: { data: FieldMappingResponse }) => {
      return (
        <DirectMappingToggleButton
          data={data}
          onSelectionChagne={(isChecked: boolean) => {
            setDirectMappingRowData(data.data);
            if (isChecked === true) {
              setIsDirectMappingToggleConfirmVisible(true);
            } else {
              // No propmt is required for turning off direct mapping
              props.onDirectMappingToggle?.(data.data, isChecked);
            }
          }}
        />
      );
    },
    []
  );

  const ContentControlComponent = useCallback(
    (componentProps: { data: { data: FieldMappingResponse } }) => {
      return (
        <ContentControlCellComponent
          fieldMapping={componentProps.data.data}
          isPreviewChange={props.isPreviewChange}
        />
      );
    },
    []
  );

  const toolbarConfig = useRef({
    dislplaySearchPanel: props.displaySearch !== false,
  });

  return (
    <>
      <div>
        <PromineoViewGrid
          dataSource={props.fieldMappings}
          className="promineo-ui-grid"
          toolbarConfig={toolbarConfig.current}
          height={props.height}
        >
          <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
          <Column
            caption={"Planning object type"}
            dataField="planningObjectTypeText"
          />
          <Column caption={"ILAP term"} dataField="name" />
          <Column caption={"Description"} dataField="description" />
          <Column caption={"Data type"} dataField="dataTypeText" />
          <Column
            caption={"Content control"}
            cellComponent={ContentControlComponent}
          />
          {!props.isEdit && (
            <Column
              cssClass="add-left-border"
              caption={"Host field (alias)"}
              dataField="mappedConnectorHostFieldName"
            />
          )}

          {props.isEdit && (
            <Column
              cssClass="add-left-border custom-control-padding"
              caption={"Host field (alias)"}
              dataField="mappedConnectorHostFieldName"
              cellRender={HostFieldComponent}
            />
          )}

          {!props.isEdit && (
            <Column
              caption={"Direct mapping"}
              dataField="hasDirectMapping"
              trueText="Yes"
              falseText={"No"}
              showEditorAlways={false}
            />
          )}
          {props.isEdit && (
            <Column
              caption={"Direct mapping"}
              dataField="hasDirectMapping"
              cellRender={DirectMappingToggleButtonComponent}
              width="150"
              alignment={"left"}
            />
          )}
        </PromineoViewGrid>
      </div>

      {isDirectMappingToggleConfirmVisible ? (
        <PromineoConfirmationDialog
          actionOptions={{
            mode: PromineoModalMode.Modify,
          }}
          content={"Are you sure you want to turn on direct mapping?"}
          subContent="This action would delete any unsaved value maps."
          isDeleteConfirm={false}
          confirmButtonText="Confirm"
          onConfirm={onDirectMappingToggleOnConfirm}
          onCancel={() => {
            setIsDirectMappingToggleConfirmVisible(false);
          }}
        ></PromineoConfirmationDialog>
      ) : (
        <></>
      )}
    </>
  );
}
