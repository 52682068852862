import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import TemplateViewerHeader from "./ViewTemplateHeader";
import TemplateViewerGrid from "./ViewTemplateGrid";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadTemplateByIdAsync } from "store/actions/TemplateActions";
import TemplateHeaderData from "interfaces/component-data/TemplateHeaderData";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";

export default function ViewTemplate() {
  const param = useParams();
  const dispatch = useAppDispatch();
  const [templateHeaderData, setTemplateHeaderData] =
    useState<TemplateHeaderData | null>(null);

  const template = useAppSelector(
    (state) => state.templateData.currentTemplate
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadTemplateByIdAsync(Number(param.id))).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    if (template) {
      const headerData: TemplateHeaderData = {
        id: template.id,
        title: template.title,
        ownerTenant: template.ownerTenant,
        labels: template.labels,
        fieldCount: template.fieldCount,
        ownConfigsCount: template.ownConfigsCount,
        shareCount: template.shareCount,
        tenantId: template.tenantId,
        description: template.description,
      };
      setTemplateHeaderData(headerData);
    }
  }, [template]);

  const headerDivId: string = "view-template-header";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 70,
  });

  return (
    <div>
      <div id={headerDivId}>
        <TemplateViewerHeader templateHeaderData={templateHeaderData} />
      </div>
      <TemplateViewerGrid
        templateFieldData={template?.fields}
        height={gridHeight}
      />
    </div>
  );
}
