import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import { EditorMode } from "shared/enums/EditorMode";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";
import TextViewerComponent from "./TextViewerComponent";
import { IncludeMappingType } from "shared/enums/feature/IncludeMappingTypeEnum";

interface Props {
  mode: EditorMode;
  includeMappingsTypeValue: IncludeMappingType;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function OnlyIncludeSpecifiedMappings(props: Props) {
  const { mode, includeMappingsTypeValue, handleConfigurationValueChanged } =
    props;

  const includeMappingBooleanValue =
    includeMappingsTypeValue === IncludeMappingType.OnlySpecifiedMappings
      ? true
      : false;
  const displayText = includeMappingBooleanValue ? "On" : "Off";

  const includeMappingTypeValueChange = (mappingTypeValue: boolean) => {
    const enumValue =
      mappingTypeValue === true
        ? IncludeMappingType.OnlySpecifiedMappings
        : IncludeMappingType.CoreFieldsAndSpecifiedMappings;

    if (handleConfigurationValueChanged) {
      handleConfigurationValueChanged("includeMapping", enumValue);
    }
  };

  return (
    <>
      {mode === EditorMode.Edit && (
        <PromineoSwitchButton
          checked={includeMappingBooleanValue}
          switchType={SwitchButtonType.Default}
          onChange={() => {
            includeMappingTypeValueChange(!includeMappingBooleanValue);
          }}
        />
      )}

      {mode === EditorMode.View && <TextViewerComponent text={displayText} />}
    </>
  );
}
