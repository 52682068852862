import PromineoAccordion from "components/common/controls/PromineoAccordion";

interface Props {
  title: string;
  itemRender: (...params: any) => React.ReactNode;
  isExpanded: boolean;
}

export default function HostParameterAccordion(props: Props) {
  return (
    <PromineoAccordion
      dataSource={[{ title: props.title }]}
      collapsible={true}
      itemRender={props.itemRender}
      title={props.title}
      repaintChangesOnly={true}
      defaultSelectedIndex={props.isExpanded ? 0 : undefined}
    />
  );
}
