import { createSlice } from "@reduxjs/toolkit";
import MappingFormulaResponse from "interfaces/response/MappingFormulaResponse";
import { loadMappingFormulasAsync } from "store/actions/MappingFormulaActions";

export interface MappingFormulaState {
  mappingFormulas: MappingFormulaResponse[];
}

const initialState: MappingFormulaState = {
  mappingFormulas: [],
};

export const mappingFormulaSlice = createSlice({
  name: "mappingFormulas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadMappingFormulasAsync.fulfilled, (state, action) => {
      state.mappingFormulas = action.payload;
    });
  },
});

export default mappingFormulaSlice.reducer;