import { EditorMode } from "shared/enums/EditorMode";
import TextViewerComponent from "./TextViewerComponent";
import PromineoDropdownBox from "components/common/dropdown-box/PromineoDropdownBox";
import { useCallback, useEffect, useRef, useState } from "react";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import DataGrid, {
  Column,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import HostFieldDropdownValueResponse from "interfaces/response/HostFieldDropdownValueResponse";

interface Props {
  mode: EditorMode;
  fieldName: string;
  selectedValuesString: string;
  structuresToExportCandidates: HostFieldDropdownValueResponse[];
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
}

export default function StructuresToExportSelectionComponent(props: Props) {
  const {
    mode,
    selectedValuesString,
    fieldName,
    structuresToExportCandidates,
    handleConfigurationValueChanged,
  } = props;

  const gridRef = useRef<DataGrid>(null);
  const [selectedStructureValues, setSelectedStructureValues] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (selectedValuesString && structuresToExportCandidates) {
      const selectedValues = selectedValuesString
        .split(",")
        .map((v) => v.trim())
        .filter((v) =>
          structuresToExportCandidates.find((cn) => cn.value === v)
        );
      setSelectedStructureValues(selectedValues);
    }
  }, [selectedValuesString, structuresToExportCandidates]);

  const getDisplayNameListStringFromValueListString = () => {
    const selectedValueList = selectedValuesString
      .split(",")
      .map((v) => v.trim());

    const selectedStructureDisplayNameList = structuresToExportCandidates
      .filter((s) => selectedValueList.find((v) => v === s.value))
      .map((s) => s.displayName);
    return selectedStructureDisplayNameList.join(", ");
  };

  const markAllSelectedRows = (selectedStructureList: any) => {
    if (selectedStructureList) {
      gridRef.current?.instance.selectRows(selectedStructureList, false);
    }
  };

  const handleOnContentReady = useCallback(
    (evt: any) => {
      const datasource = evt.component?.getDataSource().items();
      if (datasource && datasource.length && selectedStructureValues) {
        markAllSelectedRows(selectedStructureValues);
      }
    },
    [selectedStructureValues]
  );

  const getGridView = useCallback(
    () => (
      <PromineoViewGrid
        height={300}
        dataSource={structuresToExportCandidates}
        onContentReady={handleOnContentReady}
        keyExpr={"value"}
        onSelectionChanged={onSelectionChange}
        ref={gridRef}
      >
        <Selection
          mode="multiple"
          selectAllMode={"allPages"}
          showCheckBoxesMode={"always"}
        />
        <Scrolling mode="virtual" />
        <Column dataField={"displayName"} caption={"Structures"} />
      </PromineoViewGrid>
    ),
    [structuresToExportCandidates, handleOnContentReady]
  );

  const onDropDownSelectionChange = (changedSelectedStructureList: any) => {
    if (changedSelectedStructureList) {
      markAllSelectedRows(changedSelectedStructureList);
      setSelectedStructureValues(changedSelectedStructureList);
      if (handleConfigurationValueChanged) {
        const value = changedSelectedStructureList.join(", ");
        handleConfigurationValueChanged(fieldName, value);
      }
    }
  };

  const onSelectionChange = useCallback((e: any) => {
    const selectedStructureList =
      (e.selectedRowKeys.length && e.selectedRowKeys) || [];
    onDropDownSelectionChange(selectedStructureList);
  }, []);

  const onClearButtonClick = (e: any) => {
    // On clear button click nall value is provided
    if (e.value === null) {
      onDropDownSelectionChange([]);
    }
  };

  const dropdownOptions = useRef({
    maxHeight: 380,
    height: "auto",
  });

  return (
    <>
      {mode === EditorMode.Edit && (
        <PromineoDropdownBox
          value={selectedStructureValues}
          valueExpr={"value"}
          displayExpr={"displayName"}
          placeholder="Type..."
          showClearButton={true}
          dataSource={structuresToExportCandidates}
          {...dropdownOptions}
          dropDownOptions={dropdownOptions.current}
          onValueChanged={onClearButtonClick}
          className={`w-full`}
          contentRender={getGridView}
        />
      )}

      {mode === EditorMode.View && (
        <TextViewerComponent
          text={
            selectedValuesString
              ? getDisplayNameListStringFromValueListString()
              : "-"
          }
        />
      )}
    </>
  );
}
