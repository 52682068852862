import { createSlice } from "@reduxjs/toolkit";
import {
  loadActiveExchangeAgreementsAsync,
  loadNewExchangeAgreementsAsync,
  loadExchangeAgreementDetailsAsync,
  loadActiveSimplifiedExchangeAgreementsAsync,
  updateExistingExchangeAgreementForPartnerAsync,
  signExchangeAgreementOnBehalfOfPartnerAsync,
  signExchangeAgreementOnBehalfOfOwnerAsync,
  pauseActiveExchangeAgreementAsync,
  resumeActiveExchangeAgreementAsync,
  removeInactiveExchangeAgreementAsync,
  loadConfigChangesPreviewAsync,
  republishExchangeAgreementForConfigChangeAsync,
  updateExistingExchangeAgreementAsync,
  loadArchiveExchangeAgreementsAsync,
} from "store/actions/ExchangeAgreementActions";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import InactiveExchangeAgreementListingResponse from "interfaces/response/NewExchangeAgreementListingResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import ExchangeAgreementSimplifiedResponse from "interfaces/response/ExchangeAgreementSimplifiedResponse";
import { ExchangeAgreementConfigUpdatePreviewResponse } from "interfaces/response/ExchangeAgreementConfigUpdatePreviewResponse";
import ArchiveExchangeAgreementListingResponse from "interfaces/response/ArchiveExchangeAgreementListingResponse";

export interface IEAState {
  activeExchangeAgreements: ActiveExchangeAgreementListingResponse[];
  newExchangeAgreements: InactiveExchangeAgreementListingResponse[];
  archiveExchangeAgreements: ArchiveExchangeAgreementListingResponse[];
  selectedExchangeAgreement: ExchangeAgreementDetailedResponse | null;
  activeExchangeAgreementsSimplified: ExchangeAgreementSimplifiedResponse[];
  configChangesPreview: ExchangeAgreementConfigUpdatePreviewResponse | null;
}

const initialState: IEAState = {
  activeExchangeAgreements: [],
  newExchangeAgreements: [],
  archiveExchangeAgreements: [],
  selectedExchangeAgreement: null,
  activeExchangeAgreementsSimplified: [],
  configChangesPreview: null,
};

export const exchangeAgreementSlice = createSlice({
  name: "ExchangeAgreements",
  initialState,
  reducers: {
    resetSelectedExchangeAgreement: (state) => {
      state.selectedExchangeAgreement = null;
    },
    resetDataTransferConflictStatus: (state, action) => {
      state.activeExchangeAgreements = state.activeExchangeAgreements.map(
        (ea) => {
          if (ea.id === action.payload) {
            ea.isLastDownloadFailedDueToConflicts = false;
          }
          return ea;
        }
      );
    },
    resetConfigChangesPreview: (state) => {
      state.configChangesPreview = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadActiveExchangeAgreementsAsync.fulfilled,
      (state, action) => {
        state.activeExchangeAgreements = action.payload;
      }
    );
    builder.addCase(
      loadNewExchangeAgreementsAsync.fulfilled,
      (state, action) => {
        state.newExchangeAgreements = action.payload;
      }
    );
    builder.addCase(
      loadArchiveExchangeAgreementsAsync.fulfilled,
      (state, action) => {
        state.archiveExchangeAgreements = action.payload;
      }
    );
    builder.addCase(loadExchangeAgreementDetailsAsync.pending, (state) => {
      state.selectedExchangeAgreement = null;
    });
    builder.addCase(
      loadExchangeAgreementDetailsAsync.fulfilled,
      (state, action) => {
        state.selectedExchangeAgreement = action.payload;
      }
    );
    builder.addCase(
      loadActiveSimplifiedExchangeAgreementsAsync.fulfilled,
      (state, action) => {
        state.activeExchangeAgreementsSimplified = action.payload;
      }
    );

    builder.addCase(
      updateExistingExchangeAgreementForPartnerAsync.fulfilled,
      (state, action) => {
        state.selectedExchangeAgreement = action.payload;
      }
    );

    builder.addCase(
      updateExistingExchangeAgreementAsync.fulfilled,
      (state, action) => {
        state.selectedExchangeAgreement = action.payload;
      }
    );

    builder.addCase(
      signExchangeAgreementOnBehalfOfPartnerAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          state.selectedExchangeAgreement.partnerSigner =
            action.payload.partnerSigner;
          state.selectedExchangeAgreement.partnerSignatureTime =
            action.payload.partnerSignatureTime;
        }
      }
    );

    builder.addCase(
      signExchangeAgreementOnBehalfOfOwnerAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          state.selectedExchangeAgreement.ownerSigner =
            action.payload.ownerSigner;
          state.selectedExchangeAgreement.ownerSignatureTime =
            action.payload.ownerSignatureTime;
        }
      }
    );

    builder.addCase(pauseActiveExchangeAgreementAsync.fulfilled, (state) => {
      if (state.selectedExchangeAgreement) {
        state.selectedExchangeAgreement.isPaused = true;
      }
    });

    builder.addCase(resumeActiveExchangeAgreementAsync.fulfilled, (state) => {
      if (state.selectedExchangeAgreement) {
        state.selectedExchangeAgreement.isPaused = false;
      }
    });

    builder.addCase(removeInactiveExchangeAgreementAsync.fulfilled, (state) => {
      if (state.selectedExchangeAgreement) {
        state.selectedExchangeAgreement = null;
      }
    });

    builder.addCase(
      loadConfigChangesPreviewAsync.fulfilled,
      (state, action) => {
        state.configChangesPreview = action.payload;
      }
    );

    builder.addCase(
      republishExchangeAgreementForConfigChangeAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          state.selectedExchangeAgreement = {
            ...state.selectedExchangeAgreement,
            ...action.payload,
            updateRequired: false,
          };
        }
      }
    );
  },
});

export const {
  resetSelectedExchangeAgreement,
  resetDataTransferConflictStatus,
  resetConfigChangesPreview,
} = exchangeAgreementSlice.actions;

export default exchangeAgreementSlice.reducer;
