export enum MappingFormulaType {
  Empty = 0,
  Boolean = 1,
  String = 2,
  DateTime = 3,
  General = 4,
  ValueMap = 5,
  Array = 6,
  Aggregation = 7,
  Math = 8,
}