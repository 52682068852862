import TickIcon from "components/icons/TickIcon";

interface Props {
  tenantStatus: string;
}

export default function TenantStatusLabel(props: Props) {
  const tenantStatus = props.tenantStatus;

  const commonClasses =
    "flex justify-center items-center rounded-[10px] py-1 gap-x-1 text-10px leading-[15px] font-medium";

  return (
    <div className="w-[64px]">
      <div className={`bg-lightGreen ${commonClasses}`}>
        <TickIcon />
        {tenantStatus}
      </div>
    </div>
  );
}
