import PromineoViewGrid, {
  PromineoGridToolbarConfig,
} from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import TenantAdminContentControlLabelComponent from "features/tenant-admin/content-control/TenantAdminContentControlLabelComponent";
import { useCallback, useRef } from "react";
import NumberOfTemplates from "./NumberOfTemplates";
import { useNavigate } from "react-router-dom";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import { TENANT_ADMIN_FIELDS } from "shared/constants/RoutePathConstants";
import TenantAdminFieldResponse from "interfaces/response/TenantAdminFieldResponse";

interface Props {
  fields: TenantAdminFieldResponse[];
  height: number;
}

export default function ContentControlGrid(props: Props) {
  const navigate = useNavigate();

  const ContentControlComponent = useCallback(
    (props: { data: TenantAdminFieldResponse }) => {
      return <TenantAdminContentControlLabelComponent field={props.data} />;
    },
    []
  );

  const NumberOfTemplateComponent = useCallback(
    (props: { data: TenantAdminFieldResponse }) => {
      return (
        props.data.templateUsageCount > 0
          ? <NumberOfTemplates data={props.data} />
          : <div>{props.data.templateUsageCount}</div>
      )
    }, []
  );

  const handleRowClick = (evt: { data: FieldDetailsResponse }) => {
    navigate(`${TENANT_ADMIN_FIELDS}/${evt.data.id}`);
  };

  const toolbarConfig = useRef<PromineoGridToolbarConfig>({
    dislplaySearchPanel: true,
  });

  return (
    <div className="flex justify-center">
      <PromineoViewGrid
        dataSource={props.fields}
        onRowClick={handleRowClick}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig.current}
        height={props.height}
      >
        <Scrolling rowRenderingMode={"virtual"} mode={"virtual"} />
        <Column
          caption={"Planning object type"}
          dataField="planningObjectTypesText"
          alignment="left"
        />
        <Column caption={"ILAP Term"} dataField="name" alignment="left" />
        <Column
          caption={"Description"}
          dataField="description"
          alignment="left"
        />
        <Column
          caption={"Data type"}
          dataField="dataTypeText"
          alignment="left"
        />
        <Column
          caption={"# of templates using ILAP term"}
          dataField="numberOfTemplatesUsingIlapTerm"
          cellRender={NumberOfTemplateComponent}
          alignment="left"
          cssClass={"ignore-row-click"}
        />
        <Column
          caption={"Content control"}
          dataField="contentControlText"
          alignment="left"
          cssClass="custom-control-padding ignore-row-click"
          cellRender={ContentControlComponent}
          allowFiltering={false}
        />
      </PromineoViewGrid>
    </div>
  );
}
