import { StepTwoNewIeaProps } from "features/ieas/active-ieas/new-iea/StepTwoNewIEA";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import ConfigResponse from "interfaces/response/ConfigResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import MySelfResponse from "interfaces/response/MySelfResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import { useEffect, useMemo, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";

interface Props {
  ownerConfiguration: ConfigForExchangeAgreementResponse[];
  direction: ConfigDirection;
  templateId: number;
  loggedInUser: MySelfResponse;
  ownerConfig: ConfigResponse | null;
}

interface HookResponseType {
  stepTwoProps: StepTwoNewIeaProps;
  ownerConfigRequest: OwnerConfigRequest;
  isOwnerConfigRequestValid: boolean;
}

interface OwnerConfigRequest {
  selectedTemplateId: number;
  selectedDirection: ConfigDirection;
  connectorId: number;
  scheduleId: number;
  ownerRepresentatives: number[];
  ownerSchedule?: ConnectorScheduleSimplifiedResponse;
  selectedConnector?: ConnectorBaseResponse;
}

const defaultOwnerConfigRequest: OwnerConfigRequest = {
  selectedTemplateId: 0,
  connectorId: 0,
  scheduleId: 0,
  selectedDirection: ConfigDirection.Receiving,
  ownerRepresentatives: [],
};

export default function useStepTwoNewIeaDataHook(
  props: Props
): HookResponseType {
  const [isValidRequest, setIsValidRequest] = useState(false);

  const [ownerConfigRequest, setOwnerConfigRequest] =
    useState<OwnerConfigRequest>({
      ...defaultOwnerConfigRequest,
      ownerRepresentatives: [props.loggedInUser.id],
    });

  useEffect(() => {
    setOwnerConfigRequest((prev) => {
      return {
        ...prev,
        selectedTemplateId: props.templateId,
        scheduleId: 0,
        connectorId: 0,
      };
    });
  }, [props.templateId]);

  useEffect(() => {
    setOwnerConfigRequest((prev) => {
      return {
        ...prev,
        scheduleId: 0,
        connectorId: 0,
        selectedDirection: props.direction,
      };
    });
  }, [props.direction]);

  useEffect(() => {
    let isValid = true;
    if (
      !ownerConfigRequest.scheduleId ||
      !ownerConfigRequest.connectorId ||
      !ownerConfigRequest.ownerRepresentatives.length
    ) {
      isValid = false;
    }
    setIsValidRequest(isValid);
  }, [ownerConfigRequest]);

  const onConnectorChange = (connector: ConnectorBaseResponse) => {
    setOwnerConfigRequest((prev) => {
      return {
        ...prev,
        connectorId: connector.id,
        selectedConnector: connector,
      };
    });
  };

  const onScheduleChange = (schedule: ConnectorScheduleSimplifiedResponse) => {
    setOwnerConfigRequest((prev) => {
      return { ...prev, scheduleId: schedule.id, ownerSchedule: schedule };
    });
  };

  const onOwnerRepresentativeChange = (values: number[]) => {
    setOwnerConfigRequest((prev) => {
      const validValues = values.filter((v) => v && v > 0);
      return {
        ...prev,
        ownerRepresentatives: [props.loggedInUser.id, ...validValues],
      };
    });
  };

  const response = useMemo<HookResponseType>(() => {
    return {
      stepTwoProps: {
        ownerConfigurationProps: {
          direction: props.direction,
          templateId: props.templateId,
          loggedInUser: props.loggedInUser,
          ownerRole:
            props.direction === ConfigDirection.Receiving
              ? ExchangeRoleEnum.Receiver
              : ExchangeRoleEnum.Sender,
          connectorId: ownerConfigRequest.connectorId,
          scheduleId: ownerConfigRequest.scheduleId,
          ownerConfig: props.ownerConfig,
          ownerConfiguration: props.ownerConfiguration,
          ownerRepresentatives: ownerConfigRequest.ownerRepresentatives,
          // TODO: Remove
          onValueChange: () => {},
          onOwnerRepresentativeChange: onOwnerRepresentativeChange,
          onConnectorSelectionChange: onConnectorChange,
          onOwnerScheduleSelectionChange: onScheduleChange,
        },
      },
      isOwnerConfigRequestValid: isValidRequest,
      ownerConfigRequest,
    };
  }, [ownerConfigRequest, props, isValidRequest]);

  return response;
}
