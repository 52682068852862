import { Accordion } from "devextreme-react";
import { IAccordionOptions } from "devextreme-react/accordion";
import "./styles/PromineoAccordion.css";

interface Props {
  title?: string;
}

const TitleCellRender = (props: { title?: string }) => {
  return props.title ? <div>{props.title}</div> : null;
};

export default function PromineoAccordion(props: Props & IAccordionOptions) {
  return (
    <Accordion
      className="promineo-accordion"
      itemTitleRender={TitleCellRender}
      {...props}
    />
  );
}
