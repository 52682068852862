import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoModal from "components/modal/PromineoModal";
import { Item } from "devextreme-react/tab-panel";
import TemplateConfigurationUpdatePreviewModalHeader from "features/common/template-configuration-update/TemplateConfigurationUpdatePreviewModalHeader";
import TemplateChangePreviewGrid from "features/setup/configs/template-update/TemplateChangePreviewGrid";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useEffect } from "react";
import {
  TemplateConfigUpdatePreviewModalHeight,
  TemplateConfigUpdatePreviewModalWidth,
  TemplateConfigUpdatePreviewTabPanelHeight,
} from "shared/constants/CommonConstants";
import { TemplateConfigUpdatePreviewGridHeight } from "shared/constants/GridConstants";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ConfigUpdateMappingPreview from "./ConfigUpdateMappingPreview";
import ConfigUpdateValueTransformationPreview from "./ConfigUpdateValueTransformationPreview";
import ConfigUpdateContentControlPreview from "./ConfigUpdateContentControlPreview";
import {
  loadConfigChangesPreviewAsync,
  republishExchangeAgreementForConfigChangeAsync,
} from "store/actions/ExchangeAgreementActions";
import { toastSuccess } from "shared/utilities/ToastUtility";

interface Props {
  onCancel: () => void;
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export default function ConfigurationUpdatePreviewModal(props: Props) {
  const dispatch = useAppDispatch();

  const configChanges = useAppSelector(
    (store) => store.exchangeAgreementData.configChangesPreview
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadConfigChangesPreviewAsync(props.exchangeAgreement.id)).finally(
      hideLoadingPanel
    );
  }, []);

  const handleAcceptUpdatesClick = () => {
    displayLoadingPanel();
    dispatch(
      republishExchangeAgreementForConfigChangeAsync(props.exchangeAgreement.id)
    )
      .unwrap()
      .then(() => {
        toastSuccess("IEA configuration successfully updated.");
        props.onCancel();
      })
      .finally(hideLoadingPanel);
  };

  return (
    <PromineoModal
      isVisible={true}
      width={TemplateConfigUpdatePreviewModalWidth}
      height={TemplateConfigUpdatePreviewModalHeight}
    >
      <TemplateConfigurationUpdatePreviewModalHeader
        title="Configuration Updates"
        description="
        Below you can view the updates in the configuration of the IEA. On
        acceptance of the updates, a notification will be send to the partner
        with the content of the update. The update is performed as soon as the
        partner has also accepted the updates. Until then, the IEA continues
        with the current configuration."
      />

      <div className="mt-4">
        <PromineoTabPanel height={TemplateConfigUpdatePreviewTabPanelHeight}>
          <Item title={"Added fields"}>
            <div className="pt-4">
              <TemplateChangePreviewGrid
                fields={configChanges?.addedFields || []}
                height={TemplateConfigUpdatePreviewGridHeight}
                noDataText="No fields are added"
              />
            </div>
          </Item>
          <Item title={"Removed fields"}>
            <div className="pt-4">
              <TemplateChangePreviewGrid
                fields={configChanges?.removedFields || []}
                height={TemplateConfigUpdatePreviewGridHeight}
                noDataText="No fields are removed"
              />
            </div>
          </Item>
          <Item title={"Updated fields"}>
            <div className="pt-2">
              {configChanges?.ownerConfigUpdatedFields &&
                configChanges?.partnerConfigUpdatedFields && (
                  <ConfigUpdateContentControlPreview
                    ownerConfigUpdatedFields={configChanges.ownerConfigUpdatedFields.filter(
                      (f) => f.isModified
                    )}
                    partnerConfigUpdatedFields={configChanges.partnerConfigUpdatedFields.filter(
                      (f) => f.isModified
                    )}
                    exchangeAgreementDirection={
                      props.exchangeAgreement.direction
                    }
                    displayUpdateColumn={true}
                  />
                )}
            </div>
          </Item>
          <Item title={"Mappings"}>
            <div className="pt-2">
              {configChanges?.ownerConfigMappings &&
                configChanges?.partnerConfigMappings && (
                  <ConfigUpdateMappingPreview
                    ownerConfigMapping={configChanges.ownerConfigMappings}
                    partnerConfigMapping={configChanges.partnerConfigMappings}
                    exchangeAgreementDirection={
                      props.exchangeAgreement.direction
                    }
                  />
                )}
            </div>
          </Item>
          <Item title={"Value transformation"}>
            <div className="pt-2">
              {configChanges?.ownerConfigMappings &&
                configChanges?.partnerConfigMappings && (
                  <ConfigUpdateValueTransformationPreview
                    ownerConfigMapping={configChanges.ownerConfigMappings}
                    partnerConfigMapping={configChanges.partnerConfigMappings}
                    exchangeAgreementDirection={
                      props.exchangeAgreement.direction
                    }
                  />
                )}
            </div>
          </Item>
        </PromineoTabPanel>
      </div>
      <div className="flex justify-between mt-4">
        <PromineoButton
          text="Cancel"
          variant={PromineoButtonType.Secondary}
          onClick={props.onCancel}
        />
        <PromineoButton
          text="Accept updates"
          disabled={false}
          onClick={handleAcceptUpdatesClick}
        />
      </div>
    </PromineoModal>
  );
}
