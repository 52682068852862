import { AxiosResponse } from "axios";
import ErrorResponse from "interfaces/response/ErrorResponse";

const getPropertyCaseInsensitive = <T extends object>(
  object: T,
  key: string
): any => {
  const propKey = Object.keys(object).find(
    (k) => k.toLowerCase() === key.toLowerCase()
  ) as keyof T;

  return object[propKey];
};

export const parseErrorResponse = (error: AxiosResponse<any>) => {
  let errorMessage = "Failed to perform the request";
  const errorResponse = getPropertyCaseInsensitive(error, "response");
  let validationErrors: string[] = [];
  let errorData = null;

  if (errorResponse) {
    const errorResponseData = getPropertyCaseInsensitive(errorResponse, "data");
    if (errorResponseData) {
      errorMessage = errorResponseData.message;

      if (errorResponse.status === 400) {
        validationErrors = errorResponseData.errors;
        errorMessage = errorResponseData.message;
      }

      if (errorResponse.status === 401) {
        errorMessage = errorResponseData.title;
      }
      errorData = errorResponseData;
    }
  } else {
    // Axios do not provide status for network error. It just throws 'Network error'.
    errorMessage = "API is unreachable.";
  }

  const errorResponseObjectToReturn: ErrorResponse = {
    message: errorMessage,
    status: errorResponse?.status || null,
    errors: validationErrors,
    validationErrorMessage:
      validationErrors && validationErrors.length > 0
        ? validationErrors.join(",")
        : "",
    data: errorData
  };

  return errorResponseObjectToReturn;
};
