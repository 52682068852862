import PromineoModal from "components/modal/PromineoModal";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import { useCallback, useEffect, useState } from "react";
import ExampleFormulaPickerDropdown from "./ExampleFormulaPickerDropdown";
import { useAppDispatch, useAppSelector } from "store/hooks";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { mappingFormulaList } from "shared/constants/MappingFormula";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { toastError } from "shared/utilities/ToastUtility";
import HostFieldSelectionDropdown from "features/setup/configs/config-common/HostFieldSelectionDropdown";
import { loadMappingFormulasAsync } from "store/actions/MappingFormulaActions";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import MappingFormulaResponse from "interfaces/response/MappingFormulaResponse";

interface Props {
  fieldMapping: FieldMappingResponse;
  configDirection: ConfigDirection;
  onCancel: () => void;
  onSave: (data: FieldMappingResponse) => void;
  selectedCodeSet?: number | null;
}

export default function AddMappingFormulaDialog(props: Props) {
  const { fieldMapping, onCancel, onSave } = props;
  const [currentFormulaValue, setCurrentFormulaValue] = useState<
    string | null | undefined
  >(fieldMapping.formula);

  const [mappingFormulaDataSource, setMappingFormulaDataSource] =
    useState<MappingFormulaResponse[]>(mappingFormulaList);
  const dispatch = useAppDispatch();

  const selectedConnect = useAppSelector(
    (store) => store.connectorData.selectedConnector
  );

  const mappingFormulaForPlanningSystem = useAppSelector(
    (store) => store.mappingFormulaData.mappingFormulas
  );

  const [selectedHostField, setSelectedHostField] = useState<
    HostFieldResponse | null | undefined
  >();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadMappingFormulasAsync()).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    let existingMappingFormulaSource = mappingFormulaDataSource;
    let filteredMappingFormula = mappingFormulaForPlanningSystem.filter(
      (f) =>
        f.planningObjectType &&
        f.planningObjectType === props.fieldMapping.planningObjectType
    );

    setMappingFormulaDataSource(
      existingMappingFormulaSource.concat(filteredMappingFormula)
    );
  }, [mappingFormulaForPlanningSystem]);

  useEffect(() => {
    if (
      selectedConnect &&
      fieldMapping &&
      fieldMapping.mappedConnectorHostFieldId
    ) {
      const hostField = selectedConnect.hostFields.find(
        (field) => field.id === fieldMapping.mappedConnectorHostFieldId
      );
      setSelectedHostField(hostField);
    }
  }, [selectedConnect, fieldMapping]);

  const [isFormulaSyntaxValid, setIsFormulaSyntaxValid] =
    useState<boolean>(true);

  const checkFormulaValidity = (formula: string) => {
    let isValid = false;
    if (props.configDirection === ConfigDirection.Receiving) {
      if (selectedHostField) {
        isValid = true;
      }
    } else {
      if (selectedHostField && formula.includes("[VALUE]")) {
        isValid = true;
      } else if (!selectedHostField && !formula.includes("[VALUE]")) {
        isValid = true;
      }
    }

    return isValid;
  };

  const onFormulaSave = () => {
    if (currentFormulaValue?.trim()) {
      if (checkFormulaValidity(currentFormulaValue) === false) {
        setIsFormulaSyntaxValid(false);
      } else {
        setIsFormulaSyntaxValid(true);
        const fieldMappingToUpdate: FieldMappingResponse = {
          ...fieldMapping,
          formula: currentFormulaValue,
          mappedConnectorHostFieldName: selectedHostField
            ? selectedHostField.displayText
            : "",
          mappedConnectorHostFieldId: selectedHostField
            ? selectedHostField.id
            : 0,
        };
        onSave(fieldMappingToUpdate);
        onCancel();
      }
    } else {
      toastError("Formula is required");
    }
  };

  const handleFormulaChangeOnInput = useCallback((evt: any) => {
    setCurrentFormulaValue(evt.event?.target.value);
  }, []);

  const handleFormulaChangeOnSelection = (newFormula: string) => {
    setCurrentFormulaValue((prevFormulaValue) => {
      if (prevFormulaValue) {
        return `${prevFormulaValue} ${newFormula}`;
      } else {
        return newFormula;
      }
    });
  };

  return (
    <PromineoModal
      isVisible={true}
      displayDefaultActions={true}
      coloredBg={true}
      width={800}
      height={480}
      editorActionButtonProps={{
        mode: PromineoModalMode.Create,
        cancelButtonCaption: "Cancel",
        createButtonCaption: "Save",
        onCancel: onCancel,
        onCreate: onFormulaSave,
      }}
    >
      {fieldMapping && (
        <div>
          <div className="font-poppins font-bold leading-4 text-10px mb-18.5px">
            Mapping Formula - {fieldMapping.name}
          </div>
          <div className="ml-2">
            <div className="font-inter leading-4 text-10px mb-2 flex gap-2">
              <div className="w-48">
                Host field{" "}
                {props.configDirection === ConfigDirection.Receiving
                  ? ""
                  : "(used as [VALUE] in formula)"}
              </div>
              <HostFieldSelectionDropdown
                width={240}
                defaultValue={fieldMapping.mappedConnectorHostFieldId}
                planningObjectType={props.fieldMapping.planningObjectType}
                onHostFieldSelectionChange={setSelectedHostField}
                selectedCodeSet={props.selectedCodeSet}
                showClearButton={true}
              />
            </div>
            <div className="font-inter leading-4 text-10px mb-2 flex gap-2">
              <div className="w-48">Example formula picker</div>
              <ExampleFormulaPickerDropdown
                dataSource={mappingFormulaDataSource}
                onFormulaSelectionChange={handleFormulaChangeOnSelection}
              />
            </div>
          </div>

          <div className="font-poppins font-bold leading-4 text-10px">
            Formula
          </div>
          <PromineoMultilineTextEdit
            height={264}
            placeholder="Type..."
            className="whitespace-pre-line text-10px font-inter"
            value={currentFormulaValue ?? undefined}
            onChange={handleFormulaChangeOnInput}
          ></PromineoMultilineTextEdit>

          {!isFormulaSyntaxValid && (
            <div className="font-inter leading-4 text-10px mb-2 flex gap-2 w-48 text-red text-bold">
              Invalid syntax
            </div>
          )}
        </div>
      )}
    </PromineoModal>
  );
}
