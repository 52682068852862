import PageHeaderTitle from "components/common/page-header/PageHeaderTitle";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import { useCallback, useEffect, useState } from "react";
import AutonomousComponentGrid from "./AutonomousComponentGrid";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ClientResponse from "interfaces/response/autonomous-components/ClientResponse";
import ClientCreateRequest from "interfaces/request/autonomous-components/ClientCreateRequest";
import AutonomousComponentEditorDialog from "./AutonomousComponentEditorDialog";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadAutonomousComponentsAsync } from "store/actions/AutonomousComponentActions";
import AutonomousComponentDeleteConfirmationDialog from "./AutonomousComponentDeleteConfirmationDialog";
import { ClientTypeEnum } from "shared/enums/ClientTypeEnum";
import { AUTONOMOUS_COMPONENTS } from "shared/constants/RoutePathConstants";

export default function AutonomousComponents() {
  const dispatch = useAppDispatch();
  const [isComponentEditorVisible, setIsComponentEditorVisible] =
    useState(false);
  const [componentToAddOrEdit, setComponentToAddOrEdit] = useState<
    undefined | ClientCreateRequest
  >();
  const [componentToDelete, setComponentToDelete] = useState<
    undefined | ClientResponse
  >();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadAutonomousComponentsAsync()).finally(hideLoadingPanel);
  }, []);

  const autonomousComponentHeader = "autonomous-component-header";
  const excludedContainerIds = [autonomousComponentHeader];
  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 80,
  });

  const autonomousComponents = useAppSelector(
    (store) => store.autonomousComponentData.autonomousComponents
  );

  const handleAddNewClick = useCallback(() => {
    setComponentToAddOrEdit({
      name: "",
      description: "",
      isActive: true,
    });
    setIsComponentEditorVisible(true);
  }, []);

  const handleDeleteClick = useCallback(
    (component: ClientResponse) => {
      setComponentToDelete(component);
    },
    []
  );

  const handleEditClick = useCallback(
    (component: ClientResponse) => {
      setComponentToAddOrEdit({
        id: component.id,
        description: component.description,
        name: component.name,
        isActive: component.isActive,
      });
      setIsComponentEditorVisible(true);
    },
    []
  );

  const handleComponentEditorPopupClose = () => {
    setComponentToAddOrEdit(undefined);
    setIsComponentEditorVisible(false);
  };

  const handleDeleteConfirmationDialogClose = () => {
    setComponentToDelete(undefined);
  };

  return (
    <div>
      <div className="flex mb-4" id={autonomousComponentHeader}>
        <PageHeaderTitle title={"Autonomous Components"} />
      </div>
      <AutonomousComponentGrid
        height={gridHeight}
        clients={autonomousComponents}
        onAddNewClick={handleAddNewClick}
        onDeleteClick={handleDeleteClick}
        onEditClick={handleEditClick}
        baseUrl={AUTONOMOUS_COMPONENTS}
      />
      {isComponentEditorVisible && componentToAddOrEdit && (
        <AutonomousComponentEditorDialog
          onClose={handleComponentEditorPopupClose}
          clientCreateRequest={componentToAddOrEdit}
          clientType={ClientTypeEnum.AutonomousComponent}
        />
      )}
      <AutonomousComponentDeleteConfirmationDialog
        component={componentToDelete}
        onClose={handleDeleteConfirmationDialogClose}
        clientType={ClientTypeEnum.AutonomousComponent}
      />
    </div>
  );
}
