import { SapImportConfig } from "interfaces/host-system-config/sap/SapImportConfig";
import AnalyticsExportConfig from "interfaces/host-system-config/analytics/AnalyticsExportConfig";
import AnalyticsImportConfig from "interfaces/host-system-config/analytics/AnalyticsImportConfig";
import SafranExportConfig from "interfaces/host-system-config/safran/SafranExportConfig";
import SafranImportConfig from "interfaces/host-system-config/safran/SafranImportConfig";
import { RevisionType } from "shared/enums/RevisionTypeEnum";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { IncludeMappingType } from "shared/enums/feature/IncludeMappingTypeEnum";
import { ActivityLinkRebuildOptionEnum } from "shared/enums/feature/safran-host-system/ActivityLinkRebuildOptionEnum";
import { RFieldExportOptionsEnum } from "shared/enums/feature/safran-host-system/RFieldExportOptionsEnum";
import { ScopeEnum } from "shared/enums/feature/safran-host-system/ScopeEnum";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import PrimaveraImportConfig from "interfaces/host-system-config/primavera/PrimaveraImportConfig";
import { PrimaveraActivityLinkRebuildOption } from "shared/enums/feature/PrimaveraActivityLinkRebuildOption";
import MicrosoftProjectExportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectExportConfig";
import MicrosoftProjectImportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectImportConfig";
import SapExportConfig from "interfaces/host-system-config/sap/SapExportConfig";
import PrimaveraExportConfig from "interfaces/host-system-config/primavera/PrimaveraExportConfig";
import { SapExportFilterType } from "shared/enums/feature/sap-host-system/SapExportFilterType";

export const SapHostParameterGridKey = "_key_";

export const getDefaultHostSystemConfigurationValue = (
  hostSystem: HostSystem,
  direction: ConfigDirection
): SupportedHostSystemConfigurationType | null => {
  var hostSystemConfiguration: SupportedHostSystemConfigurationType | null =
    null;

  if (hostSystem === HostSystem.Safran) {
    if (direction === ConfigDirection.Sending) {
      hostSystemConfiguration = getSafranSendingDefaultConfiguration();
    } else {
      hostSystemConfiguration = getSafranReceivingDefaultConfiguration();
    }
  } else if (hostSystem === HostSystem.IlapAnalytics) {
    if (direction === ConfigDirection.Sending) {
      hostSystemConfiguration = getAnalyticsSendingDefaultConfiguration();
    } else {
      hostSystemConfiguration = getAnalyticsReceivingDefaultConfiguration();
    }
  } else if (hostSystem === HostSystem.SAP) {
    if (direction === ConfigDirection.Receiving) {
      hostSystemConfiguration = getSapReceivingDefaultConfiguration();
    } else {
      hostSystemConfiguration = getSapSendingDefaultConfiguration();
    }
  } else if (hostSystem === HostSystem.PrimaveraP6) {
    if (direction === ConfigDirection.Receiving) {
      hostSystemConfiguration = getPrimaveraReceivingDefaultConfiguration();
    } else {
      hostSystemConfiguration = getEmptyConfiguration();
    }
  } else if (hostSystem === HostSystem.MicrosoftProject) {
    if (direction === ConfigDirection.Sending) {
      hostSystemConfiguration =
        getMicrosoftProjectSendingDefaultConfiguration();
    } else {
      hostSystemConfiguration = getEmptyConfiguration();
    }
  }
  return hostSystemConfiguration;
};
export const getHostSystemConfigurationValue = (
  hostSystem: HostSystem,
  direction: ConfigDirection,
  existingConfiguration: string
): SupportedHostSystemConfigurationType | null => {
  var hostSystemConfiguration: SupportedHostSystemConfigurationType | null =
    null;

  if (hostSystem === HostSystem.Safran) {
    if (direction === ConfigDirection.Sending) {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<SafranExportConfig>(
          existingConfiguration
        );
    } else {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<SafranImportConfig>(
          existingConfiguration
        );
    }
  } else if (hostSystem === HostSystem.IlapAnalytics) {
    if (direction === ConfigDirection.Sending) {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<AnalyticsExportConfig>(
          existingConfiguration
        );
    } else {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<AnalyticsImportConfig>(
          existingConfiguration
        );
    }
  } else if (hostSystem === HostSystem.SAP) {
    if (direction === ConfigDirection.Receiving) {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<SapImportConfig>(
          existingConfiguration
        );
    } else if (direction === ConfigDirection.Sending) {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<SapExportConfig>(
          existingConfiguration
        );
    }
  } else if (hostSystem === HostSystem.PrimaveraP6) {
    if (direction === ConfigDirection.Receiving) {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<PrimaveraImportConfig>(
          existingConfiguration
        );
    } else {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<PrimaveraExportConfig>(
          existingConfiguration
        );
    }
  } else if (hostSystem === HostSystem.MicrosoftProject) {
    if (direction === ConfigDirection.Sending) {
      hostSystemConfiguration =
        getDeserializedHostSystemConfiguration<MicrosoftProjectExportConfig>(
          existingConfiguration
        );
    }
  }

  return hostSystemConfiguration;
};

const getSafranSendingDefaultConfiguration = (): SafranExportConfig => {
  const defaultConfiguration: SafranExportConfig = {
    activityFilter: "",
    exportScope: ScopeEnum.Total,
    rFieldsExportOption: RFieldExportOptionsEnum.ExportOnlyValuesUsed,
    exportAllResources: false,
    exportAllCalendars: false,
    wbsStructureExportFieldName: "",
    activityMetaFieldsToExclude: "",
    activityMetaFieldsToInclude: "",
    otherStructures: "",
  };

  return defaultConfiguration;
};

const getSafranReceivingDefaultConfiguration = (): SafranImportConfig => {
  const defaultConfiguration: SafranImportConfig = {
    activityFilter: "",
    allowAppendCalendars: true,
    allowAppendingTasks: true,
    allowAppendResource: true,
    allowAppendStructures: false,
    allowUpdateOfStructureDescription: false,
    activityLinkRebuildOption:
      ActivityLinkRebuildOptionEnum.RebuildIfBothActivitiesExist,
    includeMapping: IncludeMappingType.CoreFieldsAndSpecifiedMappings,
    structureImportMappings: [],
    calendarPrefix: "",
  };

  return defaultConfiguration;
};

const getSapReceivingDefaultConfiguration = (): SapImportConfig => {
  const sapImportConfig: SapImportConfig = {
    functions: [],
    stopOnError: false,
    includeMapping: 1,
  };

  return sapImportConfig;
};

const getPrimaveraReceivingDefaultConfiguration = (): PrimaveraImportConfig => {
  const primaveraImportConfig: PrimaveraImportConfig = {
    epsId: "ILAP",
    allowAppendingTasks: true,
    allowAppendResource: true,
    allowAppendingCalendars: true,
    allowAppendingStructures: false,
    doNotImportWbs: false,
    activityFilter: "",
    activityLinkRebuildOption:
      PrimaveraActivityLinkRebuildOption.RebuildIfBothActivitiesExist,
    importAsNewProject: false,
  };

  return primaveraImportConfig;
};

export const getDeserializedHostSystemConfiguration = <T>(
  hostSystemParameter: string
): T | null => {
  var hostSystemConfiguration = null;

  try {
    var existingHostSystemConfig = JSON.parse(hostSystemParameter);
    hostSystemConfiguration = existingHostSystemConfig as T;
  } catch {}

  return hostSystemConfiguration;
};

const getAnalyticsReceivingDefaultConfiguration = (): AnalyticsImportConfig => {
  const defaultConfiguration: AnalyticsImportConfig = {
    revisionType: RevisionType.Baseline,
  };

  return defaultConfiguration;
};

const getAnalyticsSendingDefaultConfiguration = (): AnalyticsExportConfig => {
  const defaultConfiguration: AnalyticsExportConfig = {
    revisionType: RevisionType.Baseline,
    activityFilter: "",
  };

  return defaultConfiguration;
};

const getMicrosoftProjectSendingDefaultConfiguration =
  (): MicrosoftProjectExportConfig => {
    const defaultConfiguration: MicrosoftProjectExportConfig = {
      includeSummaryTask: false,
      wbsStructureExportFieldName: "",
      activityFilter: "",
    };

    return defaultConfiguration;
  };

const getMicrosoftProjectReceivingDefaultConfiguration =
  (): MicrosoftProjectImportConfig => {
    const defaultConfiguration: MicrosoftProjectImportConfig = {};

    return defaultConfiguration;
  };

export const getSapSendingDefaultConfiguration = (): SapExportConfig => {
  return {
    exportFilterType: SapExportFilterType.OrderFilter,
    operationFilter: "",
    orderFilter: "",
  };
};

const getEmptyConfiguration: any = () => {
  return {};
};
